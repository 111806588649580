@import "../abstracts/color";

/****************************************ont variables**********************************************************/
$ag_lato-regular: "LatoRegular";
$ag_lato-medium: "LatoWebMedium";
$ag_lato-bold: "LatoWebBold";

/*********************************Media query breakpoint values************************************************/
$medium-min-width: 480px;
$medium-max-width: 768px;
$small-max-width: 480px;
$large-min-width: 1024px;
$large-max-width: 1350px;

/*********************************Reset Declarations***********************************************************/
$ag_base-height: 100%;
$ag_base-font-size: 14px;

/****************************************Font-Size*************************************************************/

$font-size2: 2px;
$font-size4: 4px;
$font-size6: 6px;
$font-size8: 8px;
$font-size10: 10px;
$font-size11: 11px;
$font-size12: 12px;
$font-size14: 14px;
$font-size16: 16px;
$font-size18: 18px;
$font-size20: 20px;
$font-size22: 22px;
$font-size24: 24px;
$font-size26: 26px;
$font-size28: 28px;
$font-size30: 30px;
$font-size36: 36px;
$font-size40: 40px;


/*** Agilysys base color variables ***/

$ag_base-color: $navy-blue-darkest;
$ag_base-text-black: $black;
$ag_base-text-white: $white;

$ag_border-color: $grey-light;
$ag_button-bg: $grey-lighter;

$ag_label_color:$black-lighter;

//alert-popup colors
$ag_warning-color: $orange;
$ag_error-color: $red;

// Primary header Level1 Header
$ag_primary-header-bc: $black;
$ag_primary-header-fc: $grey-light;

// Secondary header Level2 Header
$ag_secondary-header-bc: $white;
$ag_secondary-header-fc: $grey-darker;

// Disabled Color
$ag_disabled-color: $grey-darker;

//Button Color
$ag_button-primary-bc: $navy-blue-darkest;
$ag_button-primary-fc: $white;

$ag_button-secondary-bc: $white;
$ag_button-secondary1-fc: $navy-blue-darker;
$ag_button-secondary2-fc: $grey-dark;

$ag_button-tertiary-fc: $grey-darkest;

//Border color

$ag_border-lighter:$grey-lighter;

// Color Values
$ag_navy-blue-darkest:$navy-blue-darkest;
$ag_navy-blue-darker:$navy-blue-darker;
$ag_navy-blue: $navy-blue;
$ag_navy-blue-lighter: $navy-blue-lighter;
$ag_navy-blue-lightest:$navy-blue-lightest;

$ag_white: $white;
$ag_black: $black;
$ag_black-light: $black-light;
$ag_black-lighter: $black-lighter;
$ag_black-lightest: $black-lightest;

//Grey
$ag_grey-darkest: $grey-darkest;
$ag_grey-darker: $grey-darker;
$ag_grey-dark: $grey-dark;
$ag_grey-medium1: $grey-medium1;
$ag_grey-medium2: $grey-medium2;
$ag_grey-light: $grey-light;
$ag_grey-lighter: $grey-lighter;
$ag_grey-lightest: $grey-lightest;

$ag_grey-bg: $grey-lightest1;

//Green
$ag_green-darkest: $green-darkest;
$ag_green: $green;
$ag_green-lightest: $green-lightest;

//Orange
$ag_orange: $orange;
$ag_orange-lightest: $orange-lightest;

//Red
$ag_red: $red;
$ag_red-lightest: $red-lightest;

//Blue
$ag_blue: $blue;
$ag_blue-lightest: $blue-lightest;
$ag_blue-aquablue: $aqua-blue;
$ag_blue-lighter: $blue-lighter;

//Yellow
$ag_yellow: $yellow;

/**************table-values**************/
$five-px: 5px;
$ten-px: 10px;
$fifteen-px: 15px;
$twenty-px: 20px;

/** Checkbox variables ***/
$checkbox-ripple-color: $black-light;
$checkbox-size: 16px;
$checkbox-animation-ripple: 600ms;
$checkbox-animation-check: 0.2s;
$checkbox-color: $black;
$disabledCheckboxBorder: $grey-darker;

/************** common variables **********************/
$default-container-width: 460px;
$default-container-padding: 1.25rem;
$mat-toggle-button-height: 40px;
$radio-size: 16px;
$default-side-panel-width-sm: 200px;
$default-side-panel-width-lg: 250px;
$default-icon-size: 16px;
$default-control-padding: 2rem;
$tertiary-header-height: 65px;
$header-height: 50px;
$dialog-header-height: 4rem;
$dialog-footer-height: 4rem;
$toggle-height: 50px;
$header-footer-height: 130px;


/**************************Dialog Styles***********************/
$ag-primary-header-bg: $black;
$ag-primary-header-fg: $white;
$ag-base-color: $ag_base-color;






//alert-popup colors
$ag_warning-color: $orange;
$ag_error-color: $red;
$ag_success-color: $green;
$border-grey: $grey-light;






/************** background color variables **********************/

$ag_bg-color: $blue-light;






