// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$ag-rewrite-typography: mat.define-typography-config(
    $font-family: $ag_lato-regular,
    $button: mat.define-typography-level(14px, 14px, none),
    $input:  mat.define-typography-level(16px, 1.125, 400),
    $caption: mat.define-typography-level(14px, 20px, 400),
);

@include mat.core($ag-rewrite-typography);
/* For use in src/lib/core/theming/_palette.scss */
$md-mcgpalette0: (
    50 : #ebf1fa,
    100 : #c2d5f0,
    200 : #99b9e5,
    300 : #719ddb,
    400 : #4880d1,
    500 : #2e67b7,
    600 : #24508e,
    700 : #1a3966,
    800 : #1a3966,
    900 : #1a3966,
    A100 : #1a3966,
    A200 : #1a3966,
    A400 : #1a3966,
    A700 : #1a3966,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);



// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ag-rewrite-primary: mat.define-palette($md-mcgpalette0);
$ag-rewrite-accent: mat.define-palette($md-mcgpalette0, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ag-rewrite-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$ag-rewrite-theme: mat.define-light-theme($ag-rewrite-primary, $ag-rewrite-accent, $ag-rewrite-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ag-rewrite-theme);

/* You can add global styles to this file, and also import other style files */