@import './variables';

@import './_color';
/*
  ****
  @Define the Media Queries for Tablet, Desktop
  @Params Pass the Paramenter
  ****
*/

$ag_media-queries:(
    "small":  ( breakpoint:'(max-width: #{$small-max-width})'),
    "medium": ( breakpoint:'(min-width: #{$medium-min-width}) and (max-width: #{$medium-max-width})'),
    "large":  ( breakpoint:'(min-width: #{$large-min-width}) and (max-width: #{$large-max-width})')
);

@mixin ag_media-query ($size) {
    @each $item, $value in $ag_media-queries {
            @if $item == $size {
                @if map-get($value, breakpoint) != null {
                        @media only screen and #{map-get($value, breakpoint)}{
                            @content;
                }
            }
        }
    }
}

@mixin sticky-header($top, $bgColor, $zindex) {
    position: sticky;
    top: $top;
    background-color:$bgColor;
    z-index: $zindex;
}

@mixin set-browser-prefix($property, $val) {
  -webkit-#{$property}: $val;
  -moz-#{$property}: $val;
  -ms-#{$property}: $val;
  -o-#{$property}: $val;
  #{$property}: $val;
}
// Call the above method like this ---> .box { @include set-browser-prefix('transform', rotate(30deg)); }

@mixin media-query($min-width: null, $max-width: null)
{   
    @if ($min-width and $max-width) {
        @media screen and (max-width: $max-width) and (min-width: $min-width) {
            @content;
        }
    }
    @else if($max-width) {
        @media screen and (max-width: $max-width){
            @content;
        }
    }
    @else if($min-width) {
        @media screen and (min-width: $min-width){
            @content;
        }
    }
}