@import "../abstracts/variables";
//set base height of application
html,
body {
  height: $ag_base-height;
}
body {
  margin: 0;
  font-family: $ag_lato-regular;
  font-size: $ag_base-font-size;
}

//create the font size and font family for Ag application
//even font will 1 to 24
//every font has regular medium bold
//Inspect the browser type .ag_ show the all looped fonts shown

@for $i from 1 through 24 {
  @each $name in $ag_lato-regular $ag_lato-bold $ag_lato-medium {
    .ag_#{$name}-#{$i} {
      @if ($name == $ag_lato-regular) {
        font-family: $ag_lato-regular;
      } @else if($name == $ag_lato-bold) {
        font-family: $ag_lato-bold;
      } @else if($name == $ag_lato-medium) {
        font-family: $ag_lato-medium;
      }
      font-size: #{$i}px !important;
    }
  }
}
