@import '../abstracts/variables';
@import '../abstracts/_mixins';


.ag_button {
  height: 40px !important;
  line-height: 1 !important;
  @extend .ag_mr--2;
  @extend .ag_mb--2;
  padding: 0 10px;
  font-family: $ag_lato-medium;
}

.ag_button--primary{
  @extend .ag_button;
  background-color: $ag_button-primary-bc !important;
  color: $ag_button-primary-fc !important;
  border: 1px solid $ag_button-primary-bc !important;
  @include set-browser-prefix('border-radius', 5px);
  &[disabled] {
    @extend .ag_button--disabled;
  }
}

// Secondary Button with Blue font and border

.ag_button--secondary{
  @extend .ag_button;
  background-color: $ag_button-secondary-bc !important;
  border: 2px solid $ag_button-secondary1-fc !important;
  color: $ag_button-secondary1-fc;
  @include set-browser-prefix('border-radius', 5px);
  &[disabled] {
    @extend .ag_button--disabled;
  }
}

// Secondary Button with Grey font and border
.ag_button--secondary2{
  @extend .ag_button;
  background-color: $ag_button-secondary-bc !important;
  border: 2px solid $ag_button-secondary2-fc !important;
  @include set-browser-prefix('border-radius', 5px);
  &[disabled] {
    @extend .ag_button--disabled;
  }
}

.ag_button--tertiary{
  @extend .ag_button;
  background-color: transparent !important;
  border: 1px solid transparent !important;
  color: $ag_button-tertiary-fc;
  &[disabled]{
    @extend .ag_button--disabled;
    &::before{
      color: $ag_disabled-color !important;
    }
  }
}

.ag_button--singleselect{
  @extend .ag_button;
  @extend .ag_LatoRegular-12;
  background-color: $ag_black-light !important;
  border: 1px solid $ag_black-light !important;
  &[disabled] {
    @extend .ag_button--disabled;
  }
}

.ag_button--disabled{
  @extend .ag_button;
  background-color: $ag_grey-lighter !important;
  color: $ag_disabled-color !important;
  cursor: default !important;
  pointer-events: none !important;
  opacity: 0.5;
  border:none !important;
  &::before{
    color: $ag_disabled-color !important;
  }
}

.ag_button__icon--disabled{
  @extend .ag_button--disabled;
  background-color: unset !important;
}