/* You can add global styles to this file, and also import other style files */
@import "assets/sass/main";
@import "assets/sass/abstracts/color";
@import "assets/sass/abstracts/variables";
@import "assets/sass/base/base";
@import "assets/icons/style.css";

body {
  user-select: none;
  font-family: "LatoRegular", sans-serif;
}
// Display Properties
.ag_display--none {
  display: none !important;
}
.ag_display--block {
  display: block !important;
}
.ag_display--flex {
  display: flex !important;
}
.ag_display--inblock {
  display: inline-block !important;
}
.ag_display--inline {
  display: inline !important;
}
.ag_display--flex-wrap {
  @extend .ag_display--flex;
  width: 100%;
  flex-wrap: wrap;
}

// Float Properties
.ag_float--left {
  float: left;
}
.ag_float--right {
  float: right;
}

// Clear Properties
.ag_clearfix::after {
  content: "";
  clear: both;
  display: table;
}
.ag_clear--both {
  clear: both;
}

// Position Properties
.ag_position--relative {
  position: relative;
}
.ag_position--absolute {
  position: absolute;
}
.ag_position--fixed {
  position: fixed;
}
.ag_position--static {
  position: static;
}
.ag_position--sticky {
  position: sticky;
}

// Text Alignment Properties
.ag_text--left {
  text-align: left;
}
.ag_text-right {
  text-align: right;
}
.ag_text--center {
  text-align: center;
}

// Text Transform Properties
.ag_text--uppercase {
  text-transform: uppercase;
}
.ag_text--capitalize {
  text-transform: capitalize;
}
.ag_text--lowercase {
  text-transform: lowercase;
}

// Font Properties
.ag_font--normal {
  font-weight: normal;
}
.ag_font--bold {
  font-weight: bold;
}
.ag_font--italic {
  font-style: italic;
}

//Overflow Properties
.ag_text-overflow--hidden {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ag_text-overflow--auto {
  overflow: auto;
}
.ag_text-overflow--scroll {
  overflow: scroll;
}

// Cursor Properties
.ag_cursor--pointer {
  cursor: pointer;
}
.ag_cursor--move {
  cursor: move;
}
.ag_cursor--not-allowed {
  cursor: not-allowed;
}
.ag_pointer--none {
  pointer-events: none;
}

// Width Properties
@for $i from 0 through 10 {
  .ag_w--#{$i * 10} {
    width: #{$i * 10} + "%";
  }
}
.ag_w--25 {
  width: 25%;
}
.ag_w--33 {
  width: 33.33%;
}
.ag_w--75 {
  width: 75%;
}

// Height Properties
@for $i from 0 through 10 {
  .ag_h--#{$i * 10} {
    height: #{$i * 10} + "%";
  }
}
.ag_h--25 {
  height: 25%;
}
.ag_h--33 {
  height: 33.33%;
}
.ag_h--75 {
  height: 75%;
}

// Padding Properties
@for $i from 0 through 8 {
  .ag_p--#{$i} {
    padding: #{$i * 0.25}rem !important;
  }
}
@for $i from 0 through 8 {
  .ag_pl--#{$i} {
    padding-left: #{$i * 0.25}rem !important;
  }
}
@for $i from 0 through 8 {
  .ag_pr--#{$i} {
    padding-right: #{$i * 0.25}rem !important;
  }
}
@for $i from 0 through 8 {
  .ag_pt--#{$i} {
    padding-top: #{$i * 0.25}rem !important;
  }
}
@for $i from 0 through 8 {
  .ag_pb--#{$i} {
    padding-bottom: #{$i * 0.25}rem !important;
  }
}


.ag_m--unset{
  margin-left: unset !important ;
}
//Margin Proerties
.ag_m--auto {
  margin: auto;
}
.ag_ml--auto {
  margin-left: auto;
}
.ag_mr--auto {
  margin-right: auto;
}
.ag_mt--auto {
  margin-top: auto;
}
.ag_mb--auto {
  margin-bottom: auto;
}

@for $i from 0 through 8 {
  .ag_m--#{$i} {
    margin: #{$i * 0.25}rem !important;
  }
}
@for $i from 0 through 8 {
  .ag_ml--#{$i} {
    margin-left: #{$i * 0.25}rem !important;
  }
}
@for $i from 0 through 8 {
  .ag_mr--#{$i} {
    margin-right: #{$i * 0.25}rem !important;
  }
}
@for $i from 0 through 8 {
  .ag_mt--#{$i} {
    margin-top: #{$i * 0.25}rem !important;
  }
}
@for $i from 0 through 8 {
  .ag_mb--#{$i} {
    margin-bottom: #{$i * 0.25}rem !important;
  }
}

// Form related Properties
.ag_form-control {
  padding-right: $default-control-padding;
}
.ag_form-control--lg {
  @extend .ag_form-control;
  @extend .ag_w--100;
}
.ag_form-control--md {
  @extend .ag_form-control;
  @extend .ag_w--75;
}
.ag_form-control--sm {
  @extend .ag_form-control;
  @extend .ag_w--50;
}
.ag_form-control--xs {
  @extend .ag_form-control;
  @extend .ag_w--25;
}
.ag_radio--horizontal {
  @extend .ag_mb--4;
}
.ag_form--label {
  font-size: $font-size12;
  @extend .ag_display--block;
  margin-bottom: $ten-px;
  white-space: nowrap;
  color: $black;
  opacity: 0.54;
}
.ag_form--value {
  font-size: 16px;
  @extend .ag_display--block;
  @extend .ag_mb--5;
}
// Layout Properties

// Overall Outer Padding
.ag_container--padding-lg {
  @extend .ag_p--6;
}
.ag_container--padding-md {
  @extend .ag_p--5;
}
.ag_container--padding-sm {
  @extend .ag_p--4;
}
.ag_container--padding-xs {
  @extend .ag_p--3;
}

h4 {
  &.ag_state--headers {
    color: $ag_grey-darkest;
    font-size: $font-size24 !important; // change this px to rem
    @extend .ag_font--normal;
    font-family: $ag_lato-medium;
  }
  &.ag_group--headers {
    font-size: $font-size16 !important; // change this px to rem
    @extend .ag_mb--5;
    @extend .ag_font--bold;
    font-family: $ag_lato-medium;
  }
  &.ag_page--headers {
    color: $ag_grey-darkest;
    @extend .ag_mb--5;
    @extend .ag_font--bold;
    font-size: $font-size16 !important; // change this px to rem
  }
}

// .ag_footer--actions {
//   width: 100%;
//   padding: $default-control-padding;
//   background-color: $ag_white;
//   border-top: 1px solid $ag_border-lighter;
// }
.ag_footer--actions {
  width: 100%;
  padding: 10px 20px;
  background-color: $ag_white;
  border-top: 1px solid $ag_border-lighter;
  display: flex;
  position: relative;
  z-index: 1;
  height: $dialog-footer-height;
}

.ag_modal--form-height {
  height: calc(100% - #{$dialog-footer-height});
  overflow: auto;
  form {
    height: 100%;
  }
  .mat-tab-group {
    overflow: auto;
    height: 100%;
  }
}

// Disables complete section
.ag_section--disable {
  @extend .ag_cursor--not-allowed;
  @extend .ag_pointer--none;
  opacity: 0.5;
}

// Link Properties
.ag_link {
  color: $ag_base-color;
  text-decoration: none;
  @extend .ag_font--bold;
  @extend .ag_cursor--pointer;
}

.ag_link--sm {
  @extend .ag_link;
  font-size: 12px;
}

.ag_link--lg {
  @extend .ag_link;
  font-size: 14px;
}

.ag_link--disabled {
  @extend .ag_pointer--none;
  @extend .ag_cursor--not-allowed;
  color: $ag_disabled-color;
}

.ag_sticky-note {
  padding: 3px;
  font-family: $ag_lato-medium;
  @extend .ag_mr--2;
}
.ag_sticky-note-confirmed {
  @extend .ag_sticky-note;
  color: $ag_blue;
  background-color: $ag_blue-lightest;
}
.ag_sticky-note-draft {
  @extend .ag_sticky-note;
  color: $ag_orange;
  background-color: $ag_orange-lightest;
}
.ag_sticky-note-closed {
  @extend .ag_sticky-note;
  color: $ag_green;
  background-color: $ag_green-lightest;
}
.ag_sticky-note-cancelled {
  @extend .ag_sticky-note;
  color: $ag_grey-dark;
  background-color: $ag_grey-lighter;
}

// Utilities Common CSS

//Checkbox with Circle Icon
.ag_checkbox--icon {
  padding: 0.9375rem 0 0 0.9375rem;
  .icon-confirmed {
    font-size: 1.5rem;
    color: $ag_grey-darker;
    &.selected-icon {
      color: $ag_base-color;
    }
  }
  .check-label {
    font-size: 14px;
    margin-left: 0.5rem;
    vertical-align: super;
  }
}

//View Label
.labelView {
  label {
    margin-bottom: 5px;
    display: block;
    color: $ag_label_color;
    font-size: 12px;
    font-family: inherit;
  }
  p{
    margin-bottom: 20px;
  }
}

//Ngx TimePicker Color Customization
.timepicker__header {
  background: $ag_base-color !important;
}
.clock-face__number {
  span {
    &.active {
      background: $ag_base-color !important;
    }
  }
}
.timepicker-button {
  color: $ag_base-color !important;
}
.clock-face__clock-hand {
  background: $ag_base-color !important;
  &::before {
    border: 4px solid $ag_base-color !important;
  }
  &::after {
    background-color: $ag_base-color !important;
  }
}

// Ghost Css Animation
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f6f6;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}

.ghostRectangle {
  height: 25px;
  width: 100%;
  @extend .animated-background;
}
.ghostcircle {
  height: 25px;
  width: 25px;
  border-radius: 1rem;
  @extend .animated-background;
}
.ghostSquare {
  height: 25px;
  width: 25px;
  @extend .animated-background;
}
.ghostOval {
  height: 25px;
  width: 40px;
  border-radius: 5px;
  @extend .animated-background;
}

//Popover styles
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  max-width: 470px;
  padding: 8px;
  // font-family: $font_family_1;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 14px;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

  > .arrow {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 11px;

    &:after {
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      border-width: 10px;
      content: "";
    }
  }
}

.popover.top {
  margin-top: -10px;

  > .arrow {
    left: 50%;
    margin-left: -11px;
    border-bottom-width: 0;
    border-top-color: $ag_grey-dark;
    border-top-color: $ag_black-light;
    bottom: -11px;

    &:after {
      content: " ";
      bottom: 1px;
      margin-left: -10px;
      border-bottom-width: 0;
      border-top-color: $ag_border-lighter;
    }
  }
}

.popover.right {
  margin-left: 10px;

  > .arrow {
    top: 50%;
    left: -11px;
    margin-top: -11px;
    border-left-width: 0;
    border-right-color: $ag_border-lighter;

    &:after {
      content: " ";
      left: 1px;
      bottom: -10px;
      border-left-width: 0;
      border-right-color: $ag_border-lighter;
    }
  }
}

.popover.bottom {
  margin-top: 10px;

  > .arrow {
    left: 50%;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: $ag_border-lighter;
    border-bottom-color: $ag_black-light;
    top: -11px;

    &:after {
      content: " ";
      top: 1px;
      margin-left: -10px;
      border-top-width: 0;
      border-bottom-color: $ag_base-text-white;
    }
  }
}

.popover.left {
  margin-left: -10px;

  > .arrow {
    top: 50%;
    right: -11px;
    margin-top: -11px;
    border-right-width: 0;
    border-left-color: $ag_border-lighter;
    border-left-color: $ag_black-light;

    &:after {
      content: " ";
      right: 1px;
      border-right-width: 0;
      border-left-color: $ag_border-lighter;
      bottom: -10px;
    }
  }
}

.popover-title {
  margin: 0;
  padding: 8px 14px;
  font-size: 14px;
  background-color: $ag_grey-lighter;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
}

.popover-content {
 padding: 9px 14px;
}

//Common class to remove underline from the mat-form-field
.underline-hide {
  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background: none;
  }
}

//Common class to remove wrapper from the mat-form-field
.no-wrapper {
  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0px;
  }
}

//table css
table.custom-table {
  border-collapse: collapse;
  text-align: left;
  width: 100%;
  thead {
    tr {
      border-bottom: 1px solid $ag_grey-darkest;
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid $ag_grey-lighter;
      &:last-child {
        border-bottom: none;
      }
    }
  }
  th,
  td {
    @extend .ag_text-overflow--hidden;
    padding: 10px;
  }
}

.dialog-container {
  height: 100%;
  .dialog-container__header {
    display: flex;
    background-color: $ag-primary-header-bg;
    color: $ag-primary-header-fg;
    justify-content: space-between;
    border-bottom: 1px solid lightgrey;
    height: $dialog-header-height;
    align-items: center;
    margin: 0;
    padding: 0 0 0 24px;
    .dialog-container__header--close {
      cursor: pointer;
      font-size: $default-icon-size;
      display: flex;
      height: 100%;
      width: $dialog-header-height;
      align-items: center;
      justify-content: center;
      background: $ag-base-color;
    }
  }
  .dialog-container__content {
    height: calc(100% - #{$dialog-footer-height + $dialog-header-height});
    margin: 0;
    overflow: auto;
    scroll-behavior: smooth;
    &.with-footer {
      height: calc(100% - #{$dialog-header-height});
    }
  }
  .dialog-container__actions {
    border-top: 1px solid lightgrey;
    height: $dialog-footer-height;
    padding: 0 24px;
    button {
      text-transform: uppercase;
    }
  }
}

.mat-form-field-required-marker {
  color: $ag_error-color;
}

.ag_table--header{
  padding: 10px 15px 0px 15px;
  // background-color: $ag_grey-lightest;
  background-color:$ag_grey-bg;
  display: flex;
  button{
      margin-left:auto;
  }
}
.mat-tab-body-wrapper{
  height: 100%;
}

.w-100
{
  width: 100% !important;
}

.mt-3
{
  margin-top: 0.75rem;
}

.h-100
{
  height: 100%;
}
.float-left
{
  float: left;
}
// ----cdk table css---
.submodule-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.table-container {
  flex-grow: 1;
  padding: 1.25rem;
  user-select: none;
}


a.ag_button.ag_display--inflex {
  display: inline-flex;
}

a.ag_button.ag_display--inflex:hover {
  color: black;
}

.active-icon {
  margin-right: 0.5rem;
  font-size: 0.75rem;
  &:before {
    color: $green;
  }
}
.inActive-icon {
  margin-right: 0.5rem;
  font-size: 0.75rem;
  &:before {
    color: $red;
  }
}

hr {
  margin: 0px 1.25rem 10px 1.25rem;
}

:disabled {
  pointer-events: none;
}

.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: unset !important;
  background-color: rgba(0, 0, 0, 0.42) !important;
  opacity: 0.5;
}

//icons related css
.icon-No-Search-Results-2 .path1:before {
  content: "\e986";
  color: #ebf4eb;
}
.icon-No-Search-Results-2 .path2:before {
  content: "\e987";
  color: #4d8af0;
  margin-left: -1.404296875em;
}
.icon-No-Search-Results-2 .path3:before {
  content: "\e988";
  color: #fdd835;
  margin-left: -1.404296875em;
}
.icon-No-Search-Results-2 .path4:before {
  content: "\e989";
  color: #47e6b1;
  opacity: 0.5;
  margin-left: -1.404296875em;
}
.icon-No-Search-Results-2 .path5:before {
  content: "\e98a";
  color: #f55f44;
  margin-left: -1.404296875em;
}
.icon-No-Search-Results-2 .path6:before {
  content: "\e98b";
  color: #d7d7d7;
  /* opacity: 0.5; */
  margin-left: -1.404296875em;
}
.icon-No-Search-Results-2 .path7:before {
  content: "\e98c";
  color: #fff;
  margin-left: -1.404296875em;
}
.icon-No-Search-Results-2 .path8:before {
  content: "\e98d";
  color: #000;
  margin-left: -1.404296875em;
}
.icon-No-Search-Results-2 .path9:before {
  content: "\e98e";
  color: #fff;
  margin-left: -1.404296875em;
}
.icon-No-Search-Results-2 .path10:before {
  content: "\e98f";
  color: #bdbdbd;
  margin-left: -1.404296875em;
}
.icon-No-Search-Results-2 .path11:before {
  content: "\e990";
  color: #e0e0e0;
  margin-left: -1.404296875em;
}
.icon-No-Search-Results-2 .path12:before {
  content: "\e991";
  color: #43a047;
  opacity: 0.9;
  margin-left: -1.404296875em;
}
.icon-No-Search-Results-2 .path13:before {
  content: "\e992";
  color: #000;
  margin-left: -1.404296875em;
}
.icon-No-Search-Results-2 .path14:before {
  content: "\e993";
  color: #fff;
  margin-left: -1.404296875em;
}
.icon-No-Search-Results-2 .path15:before {
  content: "\e994";
  color: #bdbdbd;
  margin-left: -1.404296875em;
}
.icon-No-Search-Results-2 .path16:before {
  content: "\e995";
  color: #e0e0e0;
  margin-left: -1.404296875em;
}
.icon-No-Search-Results-2 .path17:before {
  content: "\e996";
  color: #43a047;
  opacity: 0.9;
  margin-left: -1.404296875em;
}
.icon-No-Search-Results-2 .path18:before {
  content: "\e997";
  color: #000;
  margin-left: -1.404296875em;
}
.icon-No-Search-Results-2 .path19:before {
  content: "\e998";
  color: #fff;
  margin-left: -1.404296875em;
}
.icon-No-Search-Results-2 .path20:before {
  content: "\e999";
  color: #bdbdbd;
  margin-left: -1.404296875em;
}
.icon-No-Search-Results-2 .path21:before {
  content: "\e99a";
  color: #e0e0e0;
  margin-left: -1.404296875em;
}
.icon-No-Search-Results-2 .path22:before {
  content: "\e99b";
  color: #e0e0e0;
  margin-left: -1.404296875em;
}
.icon-No-Search-Results-2 .path23:before {
  content: "\e99c";
  color: #43a047;
  opacity: 0.9;
  margin-left: -1.404296875em;
}
.icon-No-Search-Results-2 .path24:before {
  content: "\e99d";
  color: #e0e0e0;
  margin-left: -1.404296875em;
}
.icon-No-Search-Results-2 .path25:before {
  content: "\e99e";
  color: #43a047;
  margin-left: -1.404296875em;
}

.icon-No-Search-Results .path1:before {
  content: "\e979";
  color: rgb(235, 244, 235);
  /* opacity: 0.997; */
}

.icon-dashboard-cross:before {
  content: "\ebd7";
  background: linear-gradient(-90deg, #fa9386, #de6758);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.icon-new-user:before {
  content: "\ebdd";
  background: linear-gradient(-90deg, #a5adf8, #7481f1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.icon-swap1:before {
  content: "\ebe0";
  background: linear-gradient(-90deg, #6ed6f8, #3bc2ee);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.icon-dashboard-tick:before {
  content: "\ebe1";
  background: linear-gradient(-90deg, #73ecb4, #37df91);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.icon-cloudy .path2:before {
  content: "\ea43";
  margin-left: -1.4111328125em;
  color: rgb(255, 255, 255);
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16)
}

.icon-thunder .path1:before {
  content: "\ea44";
  color: rgb(255, 216, 59);
}
.icon-thunder .path2:before {
  content: "\ea45";
  margin-left: -1.0595703125em;
  color: rgb(13, 145, 224);
}
.icon-thunder .path3:before {
  content: "\ea46";
  margin-left: -1.0595703125em;
  color: rgb(13, 145, 224);
}
.icon-thunder .path4:before {
  content: "\ea47";
  margin-left: -1.0595703125em;
  color: rgb(246, 246, 246);
}
.icon-light .path1:before {
  content: "\ea48";
  color: rgb(255, 216, 59);
}
.icon-light .path2:before {
  content: "\ea49";
  margin-left: -1.0595703125em;
  color: rgb(13, 145, 224);
}
.icon-light .path3:before {
  content: "\ea4a";
  margin-left: -1.0595703125em;
  color: rgb(246, 246, 246);
}
.icon-no-appointment-booked .path1:before {
  content: "\ea4b";
  color: rgb(67, 160, 71);
  opacity: 0.1;
}
.icon-no-appointment-booked .path2:before {
  content: "\ea4c";
  margin-left: -1.494140625em;
  color: rgb(108, 99, 255);
}
.icon-no-appointment-booked .path3:before {
  content: "\ea4d";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.354;
}
.icon-no-appointment-booked .path4:before {
  content: "\ea4e";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.354;
}
.icon-no-appointment-booked .path5:before {
  content: "\ea4f";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.354;
}
.icon-no-appointment-booked .path6:before {
  content: "\ea50";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.354;
}
.icon-no-appointment-booked .path7:before {
  content: "\ea51";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.354;
}
.icon-no-appointment-booked .path8:before {
  content: "\ea52";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.354;
}
.icon-no-appointment-booked .path9:before {
  content: "\ea53";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.354;
}
.icon-no-appointment-booked .path10:before {
  content: "\ea54";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.354;
}
.icon-no-appointment-booked .path11:before {
  content: "\ea55";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.354;
}
.icon-no-appointment-booked .path12:before {
  content: "\ea56";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.354;
}
.icon-no-appointment-booked .path13:before {
  content: "\ea57";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.354;
}
.icon-no-appointment-booked .path14:before {
  content: "\ea58";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.354;
}
.icon-no-appointment-booked .path15:before {
  content: "\ea59";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.354;
}
.icon-no-appointment-booked .path16:before {
  content: "\ea5a";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.354;
}
.icon-no-appointment-booked .path17:before {
  content: "\ea5b";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.354;
}
.icon-no-appointment-booked .path18:before {
  content: "\ea5c";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.354;
}
.icon-no-appointment-booked .path19:before {
  content: "\ea5d";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.354;
}
.icon-no-appointment-booked .path20:before {
  content: "\eba1";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.354;
}
.icon-no-appointment-booked .path21:before {
  content: "\eba2";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.354;
}
.icon-no-appointment-booked .path22:before {
  content: "\eba3";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.354;
}
.icon-no-appointment-booked .path23:before {
  content: "\eba4";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.354;
}
.icon-no-appointment-booked .path24:before {
  content: "\eba5";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.354;
}
.icon-no-appointment-booked .path25:before {
  content: "\eba6";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.354;
}
.icon-no-appointment-booked .path26:before {
  content: "\eba7";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.354;
}
.icon-no-appointment-booked .path27:before {
  content: "\eba8";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.354;
}
.icon-no-appointment-booked .path28:before {
  content: "\eba9";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.354;
}
.icon-no-appointment-booked .path29:before {
  content: "\ebaa";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.354;
}
.icon-no-appointment-booked .path30:before {
  content: "\ebab";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.354;
}
.icon-no-appointment-booked .path31:before {
  content: "\ebac";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.354;
}
.icon-no-appointment-booked .path32:before {
  content: "\ebad";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.354;
}
.icon-no-appointment-booked .path33:before {
  content: "\ebae";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.354;
}
.icon-no-appointment-booked .path34:before {
  content: "\ebaf";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.354;
}
.icon-no-appointment-booked .path47:before {
  content: "\ebbc";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.1;
}
.icon-coins:before {
  content: "\eb03";
  color: #26ebc0;
}
.icon-No_records_found_aftersearch1 .path1:before {
  content: "\eb49";
  color: #43A047;
  opacity: 0.204;
}
.icon-No_records_found_aftersearch1 .path35:before {
  content: "\eb81";
  margin-left: -1.4921875em;
  color: #43A047;
  opacity: 0.9;
}
.icon-No_records_found_aftersearch1 .path43:before {
  content: "\eb89";
  margin-left: -1.4921875em;
  color: #43A047;
  opacity: 0.9;
}
.icon-No_records_found_aftersearch1 .path51:before {
  content: "\eb91";
  margin-left: -1.4921875em;
  color: #43A047;
  opacity: 0.9;
}
.icon-No_records_found_aftersearch1 .path54:before {
  content: "\eb94";
  margin-left: -1.4921875em;
  color: #43A047;
}
.icon-No_records_found_aftersearch1 .path55:before {
  content: "\eb95";
  margin-left: -1.4921875em;
  color: #43A047;
}
.icon-No_records_found_aftersearch1 .path56:before {
  content: "\eb96";
  margin-left: -1.4921875em;
  color: #43A047;
}
.icon-emptyshop .path1:before {
  content: "\e91d";
  color: #43A047;
  opacity: 0.1;
}
.icon-emptyshop .path2:before {
  content: "\e9c3";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.1;
}
.icon-emptyshop .path3:before {
  content: "\e9c4";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.1;
}
.icon-emptyshop .path4:before {
  content: "\e9c5";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.1;
}
.icon-emptyshop .path5:before {
  content: "\e9c6";
  margin-left: -1.494140625em;
  color: #43A047;
  opacity: 0.1;
}
.icon-emptyshop .path6:before {
  content: "\e9c7";
  margin-left: -1.494140625em;
  color: #43A047;
}
.icon-emptyshop .path7:before {
  content: "\e9c8";
  margin-left: -1.494140625em;
  color: #43A047;
}
.icon-emptyshop .path8:before {
  content: "\e9c9";
  margin-left: -1.494140625em;
  color: #43A047;
}
.icon-emptyshop .path9:before {
  content: "\e9ca";
  margin-left: -1.494140625em;
  color: #43A047;
}
.icon-emptyshop .path10:before {
  content: "\e9cb";
  margin-left: -1.494140625em;
  color: #43A047;
}
.icon-emptyshop .path11:before {
  content: "\e9cc";
  margin-left: -1.494140625em;
  color: rgb(255, 255, 255);
}
.icon-emptyshop .path12:before {
  content: "\e9cd";
  margin-left: -1.494140625em;
  color: #43A047;
}
.icon-emptyshop .path13:before {
  content: "\e9ce";
  margin-left: -1.494140625em;
  color: #43A047;
}
.icon-emptyshop .path14:before {
  content: "\e9cf";
  margin-left: -1.494140625em;
  color: #43A047;
}
.icon-Shop_nocart .path1:before {
  content: "\e9d0";
  color: #43A047;
  opacity: 0.1;
}
.icon-Shop_nocart .path2:before {
  content: "\e9d1";
  margin-left: -1.6533203125em;
  color: #43A047;
  opacity: 0.1;
}
.icon-Shop_nocart .path3:before {
  content: "\e9d2";
  margin-left: -1.6533203125em;
  color: #43A047;
  opacity: 0.1;
}
.icon-Shop_nocart .path4:before {
  content: "\e9d3";
  margin-left: -1.6533203125em;
  color: #43A047;
  opacity: 0.1;
}
.icon-Shop_nocart .path5:before {
  content: "\e9d4";
  margin-left: -1.6533203125em;
  color: #43A047;
  opacity: 0.1;
}
.icon-Shop_nocart .path6:before {
  content: "\e9d5";
  margin-left: -1.6533203125em;
  color: #43A047;
}
.icon-Shop_nocart .path7:before {
  content: "\e9d6";
  margin-left: -1.6533203125em;
  color: #43A047;
}
.icon-Shop_nocart .path8:before {
  content: "\e9d7";
  margin-left: -1.6533203125em;
  color: #43A047;
}
.icon-Shop_nocart .path9:before {
  content: "\e9d8";
  margin-left: -1.6533203125em;
  color: #43A047;
}
.icon-Shop_nocart .path10:before {
  content: "\e9d9";
  margin-left: -1.6533203125em;
  color: #43A047;
}
.icon-Shop_nocart .path11:before {
  content: "\e9da";
  margin-left: -1.6533203125em;
  color: #43A047;
}
.ag-snack-bar {
  min-width: 500px;
  color: white;
  padding: 10px;
  .icon-warning-icon {
      &::before {
          font-size: 20px;
      }
  }
  &.snack-info {
      background-color: $blue;
  }
  &.snack-error {
      background-color: $red;
  }
  &.snack-success {
      background-color: $green;
  }
  &.snack-warn {
      background-color: $orange;
  }


}


/****** Loader Cover Spinner for Application Loader******************/
#cover-spin,
#custom-cover-spin{
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  // background-color: transparent;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: none;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.theme-loader::after {
  content: "";
  display: block;
  width: 60px;
  height: 60px;
  border-style: solid;
  border-color: $ag_navy-blue;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}

#cover-spin::after,
#custom-cover-spin:after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60px;
  height: 60px;
  border-style: solid;
  border-color: $ag_navy-blue;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}

#custom-cover-message {
  position: absolute;
  top: calc(50% + 80px);
  left: calc(50% + 30px);
  color: #fff;
  transform: translate(-50%, -50%);
}
.AgysMatCustTooltip,.tooltipClass {
  word-break: break-word;
}

.dialog-container{
  .dialog-container__header{
    .dialog-container__header--close{
      background-color: #1a4583;
    }
    height: 60px;
    background-color: #1a4583;
  }
  .dialog-container__content{
    height: calc(100% - 122px);
  }
  .dialog-container__actions{
    height: 60px;
  }
}

.width-200{
  width: 200px;
}

.width-400{
  width: 400px;
  padding-right: 2rem;
}
.width-800{
  width: 800px;
  padding-right: 2rem;
}

.mat-tab-link.mat-tab-label-active, .mat-tab-label.mat-tab-label-active {
  background-color: #2665c0 !important;
  color: #ffffff !important;
}

.new-mat-view{
  .cdk-overlay-container{
    .cdk-overlay-pane{
      min-width: fit-content !important;
    }

  }
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

.float-right{
  float: right;
}
@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.blink-bold {
  font-weight: bold;
  animation: blink 0.5s 1;
}
