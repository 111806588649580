@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?4baoyr');
  src:  url('fonts/icomoon.eot?4baoyr#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?4baoyr') format('truetype'),
    url('fonts/icomoon.woff?4baoyr') format('woff'),
    url('fonts/icomoon.svg?4baoyr#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-sync:before {
  content: "\ecbd";
}
.icon-Send-Email:before {
  content: "\ecbc";
  color: #4e4e4e;
}

.icon-filled_up_arrow:before {
  content: "\e975";
  color: #6b6b6b;
}
.icon-menu:before {
  content: "\ecbb";
}
.icon-security:before {
  content: "\ecba";
}
.icon-language:before {
  content: "\ecb5";
}
.icon-setting:before {
  content: "\ecb6";
}
.icon-tenant:before {
  content: "\ecb7";
}
.icon-dashboard:before {
  content: "\ecb8";
}
.icon-downward:before {
  content: "\ecb9";
}
.icon-pay-card:before {
  content: "\ecac";
}
.icon-pay-cash:before {
  content: "\ecad";
}
.icon-pay-coupons:before {
  content: "\ecae";
}
.icon-pay-gift-card:before {
  content: "\ecaf";
}
.icon-pay-others:before {
  content: "\ecb0";
}
.icon-pay-pending-settlement:before {
  content: "\ecb1";
}
.icon-pay-points:before {
  content: "\ecb2";
}
.icon-pay-room-charge:before {
  content: "\ecb3";
}
.icon-tournament-player:before {
  content: "\ecab";
}
.icon-brokencloud .path1:before {
  content: "\ec65";
  color: rgb(159, 222, 247);
}
.icon-brokencloud .path2:before {
  content: "\ec66";
  margin-left: -1em;
  color: rgb(190, 234, 252);
}
.icon-brokencloud .path3:before {
  content: "\ec67";
  margin-left: -1em;
  color: rgb(174, 228, 255);
}
.icon-brokencloud .path4:before {
  content: "\ec68";
  margin-left: -1em;
  color: rgb(131, 212, 237);
}
.icon-brokencloud .path5:before {
  content: "\ec69";
  margin-left: -1em;
  color: rgb(228, 242, 249);
}
.icon-brokencloud .path6:before {
  content: "\ec6a";
  margin-left: -1em;
  color: rgb(215, 237, 249);
}
.icon-clearsky .path1:before {
  content: "\ec6b";
  color: rgb(255, 193, 7);
}
.icon-clearsky .path2:before {
  content: "\ec6c";
  margin-left: -1em;
  color: rgb(255, 213, 79);
}
.icon-clearsky .path3:before {
  content: "\ec6d";
  margin-left: -1em;
  color: rgb(255, 213, 79);
}
.icon-clearsky .path4:before {
  content: "\ec6e";
  margin-left: -1em;
  color: rgb(255, 213, 79);
}
.icon-clearsky .path5:before {
  content: "\ec6f";
  margin-left: -1em;
  color: rgb(255, 213, 79);
}
.icon-clearsky .path6:before {
  content: "\ec70";
  margin-left: -1em;
  color: rgb(255, 213, 79);
}
.icon-clearsky .path7:before {
  content: "\ec71";
  margin-left: -1em;
  color: rgb(255, 213, 79);
}
.icon-clearsky .path8:before {
  content: "\ec72";
  margin-left: -1em;
  color: rgb(255, 213, 79);
}
.icon-clearsky .path9:before {
  content: "\ec73";
  margin-left: -1em;
  color: rgb(255, 213, 79);
}
.icon-clearsky .path10:before {
  content: "\ec74";
  margin-left: -1em;
  color: rgb(255, 213, 79);
}
.icon-clearsky .path11:before {
  content: "\ec75";
  margin-left: -1em;
  color: rgb(255, 213, 79);
}
.icon-clearsky .path12:before {
  content: "\ec76";
  margin-left: -1em;
  color: rgb(255, 213, 79);
}
.icon-clearsky .path13:before {
  content: "\ec77";
  margin-left: -1em;
  color: rgb(255, 213, 79);
}
.icon-fewclouds .path1:before {
  content: "\ec78";
  color: rgb(80, 209, 239);
}
.icon-fewclouds .path2:before {
  content: "\ec79";
  margin-left: -1em;
  color: rgb(255, 237, 0);
}
.icon-foggy .path1:before {
  content: "\ec7a";
  color: rgb(110, 154, 224);
}
.icon-foggy .path2:before {
  content: "\ec7b";
  margin-left: -1em;
  color: rgb(110, 154, 224);
}
.icon-foggy .path3:before {
  content: "\ec7c";
  margin-left: -1em;
  color: rgb(110, 154, 224);
}
.icon-foggy .path4:before {
  content: "\ec7d";
  margin-left: -1em;
  color: rgb(220, 220, 218);
}
.icon-foggy .path5:before {
  content: "\ec7e";
  margin-left: -1em;
  color: rgb(171, 174, 167);
}
.icon-rain .path1:before {
  content: "\ec7f";
  color: rgb(255, 193, 7);
}
.icon-rain .path2:before {
  content: "\ec80";
  margin-left: -1em;
  color: rgb(227, 242, 253);
}
.icon-rain .path3:before {
  content: "\ec81";
  margin-left: -1em;
  color: rgb(33, 150, 243);
}
.icon-rain .path4:before {
  content: "\ec82";
  margin-left: -1em;
  color: rgb(33, 150, 243);
}
.icon-rain .path5:before {
  content: "\ec83";
  margin-left: -1em;
  color: rgb(33, 150, 243);
}
.icon-scattredcloud:before {
  content: "\ec84";
  color: #a3d4f7;
}
.icon-shower-rain .path1:before {
  content: "\ec85";
  color: rgb(132, 171, 193);
}
.icon-shower-rain .path2:before {
  content: "\ec86";
  margin-left: -1em;
  color: rgb(77, 135, 161);
}
.icon-shower-rain .path3:before {
  content: "\ec87";
  margin-left: -1em;
  color: rgb(132, 171, 193);
}
.icon-shower-rain .path4:before {
  content: "\ec88";
  margin-left: -1em;
  color: rgb(77, 135, 161);
}
.icon-shower-rain .path5:before {
  content: "\ec89";
  margin-left: -1em;
  color: rgb(132, 171, 193);
}
.icon-shower-rain .path6:before {
  content: "\ec8a";
  margin-left: -1em;
  color: rgb(77, 135, 161);
}
.icon-shower-rain .path7:before {
  content: "\ec8b";
  margin-left: -1em;
  color: rgb(132, 171, 193);
}
.icon-shower-rain .path8:before {
  content: "\ec8c";
  margin-left: -1em;
  color: rgb(77, 135, 161);
}
.icon-shower-rain .path9:before {
  content: "\ec8d";
  margin-left: -1em;
  color: rgb(132, 171, 193);
}
.icon-shower-rain .path10:before {
  content: "\ec8e";
  margin-left: -1em;
  color: rgb(77, 135, 161);
}
.icon-shower-rain .path11:before {
  content: "\ec8f";
  margin-left: -1em;
  color: rgb(132, 171, 193);
}
.icon-shower-rain .path12:before {
  content: "\ec90";
  margin-left: -1em;
  color: rgb(77, 135, 161);
}
.icon-shower-rain .path13:before {
  content: "\ec91";
  margin-left: -1em;
  color: rgb(132, 171, 193);
}
.icon-shower-rain .path14:before {
  content: "\ec92";
  margin-left: -1em;
  color: rgb(77, 135, 161);
}
.icon-shower-rain .path15:before {
  content: "\ec93";
  margin-left: -1em;
  color: rgb(132, 171, 193);
}
.icon-shower-rain .path16:before {
  content: "\ec94";
  margin-left: -1em;
  color: rgb(77, 135, 161);
}
.icon-shower-rain .path17:before {
  content: "\ec95";
  margin-left: -1em;
  color: rgb(132, 171, 193);
}
.icon-shower-rain .path18:before {
  content: "\ec96";
  margin-left: -1em;
  color: rgb(211, 221, 234);
}
.icon-shower-rain .path19:before {
  content: "\ec97";
  margin-left: -1em;
  color: rgb(132, 171, 193);
}
.icon-snows:before {
  content: "\ec98";
  color: #80c7ea;
}
.icon-thuderstorm .path1:before {
  content: "\ec99";
  color: rgb(160, 236, 255);
}
.icon-thuderstorm .path2:before {
  content: "\ec9a";
  margin-left: -1em;
  color: rgb(160, 236, 255);
}
.icon-thuderstorm .path3:before {
  content: "\ec9b";
  margin-left: -1em;
  color: rgb(160, 236, 255);
}
.icon-thuderstorm .path4:before {
  content: "\ec9c";
  margin-left: -1em;
  color: rgb(160, 236, 255);
}
.icon-thuderstorm .path5:before {
  content: "\ec9d";
  margin-left: -1em;
  color: rgb(246, 203, 67);
}
.icon-thuderstorm .path6:before {
  content: "\ec9e";
  margin-left: -1em;
  color: rgb(250, 176, 60);
}
.icon-thuderstorm .path7:before {
  content: "\ec9f";
  margin-left: -1em;
  color: rgb(160, 236, 255);
}
.icon-thuderstorm .path8:before {
  content: "\eca0";
  margin-left: -1em;
  color: rgb(160, 236, 255);
}
.icon-thuderstorm .path9:before {
  content: "\eca1";
  margin-left: -1em;
  color: rgb(160, 236, 255);
}
.icon-thuderstorm .path10:before {
  content: "\eca2";
  margin-left: -1em;
  color: rgb(109, 109, 109);
}
.icon-thuderstorm .path11:before {
  content: "\eca3";
  margin-left: -1em;
  color: rgb(86, 86, 86);
}
.icon-thuderstorm .path12:before {
  content: "\eca4";
  margin-left: -1em;
  color: rgb(109, 109, 109);
}
.icon-thuderstorm .path13:before {
  content: "\eca5";
  margin-left: -1em;
  color: rgb(109, 109, 109);
}
.icon-thuderstorm .path14:before {
  content: "\eca6";
  margin-left: -1em;
  color: rgb(160, 157, 156);
}
.icon-thuderstorm .path15:before {
  content: "\eca7";
  margin-left: -1em;
  color: rgb(130, 128, 127);
}
.icon-thuderstorm .path16:before {
  content: "\eca8";
  margin-left: -1em;
  color: rgb(160, 236, 255);
}
.icon-thuderstorm .path17:before {
  content: "\eca9";
  margin-left: -1em;
  color: rgb(160, 236, 255);
}
.icon-thuderstorm .path18:before {
  content: "\ecaa";
  margin-left: -1em;
  color: rgb(160, 236, 255);
}
.icon-kids-couple:before {
  content: "\ec52";
}
.icon-no-item-default .path1:before {
  content: "\ec53";
  color: rgb(176, 221, 233);
}
.icon-no-item-default .path2:before {
  content: "\ec54";
  margin-left: -1.0224609375em;
  color: rgb(149, 188, 201);
}
.icon-no-item-default .path3:before {
  content: "\ec55";
  margin-left: -1.0224609375em;
  color: rgb(220, 160, 80);
}
.icon-no-item-default .path4:before {
  content: "\ec56";
  margin-left: -1.0224609375em;
  color: rgb(235, 174, 86);
}
.icon-no-item-default .path5:before {
  content: "\ec57";
  margin-left: -1.0224609375em;
  color: rgb(206, 144, 71);
}
.icon-no-item-default .path6:before {
  content: "\ec58";
  margin-left: -1.0224609375em;
  color: rgb(255, 242, 180);
}
.icon-no-item-default .path7:before {
  content: "\ec59";
  margin-left: -1.0224609375em;
  color: rgb(115, 211, 122);
}
.icon-no-item-default .path8:before {
  content: "\ec5a";
  margin-left: -1.0224609375em;
  color: rgb(73, 203, 92);
}
.icon-no-item-default .path9:before {
  content: "\ec5b";
  margin-left: -1.0224609375em;
  color: rgb(221, 244, 186);
}
.icon-no-item-default .path10:before {
  content: "\ec5c";
  margin-left: -1.0224609375em;
  color: rgb(253, 122, 122);
}
.icon-no-item-default .path11:before {
  content: "\ec5d";
  margin-left: -1.0224609375em;
  color: rgb(253, 87, 87);
}
.icon-no-item-default .path12:before {
  content: "\ec5e";
  margin-left: -1.0224609375em;
  color: rgb(253, 203, 187);
}
.icon-no-item-default .path13:before {
  content: "\ec5f";
  margin-left: -1.0224609375em;
  color: rgb(100, 158, 176);
}
.icon-no-item-default .path14:before {
  content: "\ec60";
  margin-left: -1.0224609375em;
  color: rgb(69, 143, 164);
}
.icon-no-item-default .path15:before {
  content: "\ec61";
  margin-left: -1.0224609375em;
  color: rgb(176, 221, 233);
}
.icon-no-item-default .path16:before {
  content: "\ec62";
  margin-left: -1.0224609375em;
  color: rgb(142, 208, 226);
}
.icon-no-item-default .path17:before {
  content: "\ec63";
  margin-left: -1.0224609375em;
  color: rgb(241, 242, 242);
}
.icon-no-item-default .path18:before {
  content: "\ec64";
  margin-left: -1.0224609375em;
  color: rgb(95, 219, 252);
}
.icon-guest-no-data-found .path1:before {
  content: "\ec39";
  color: rgb(235, 244, 235);
}
.icon-guest-no-data-found .path2:before {
  content: "\ec3a";
  margin-left: -1.37890625em;
  color: rgb(71, 230, 177);
}
.icon-guest-no-data-found .path3:before {
  content: "\ec3b";
  margin-left: -1.37890625em;
  color: rgb(71, 230, 177);
}
.icon-guest-no-data-found .path4:before {
  content: "\ec3c";
  margin-left: -1.37890625em;
  color: rgb(71, 230, 177);
}
.icon-guest-no-data-found .path5:before {
  content: "\ec3d";
  margin-left: -1.37890625em;
  color: rgb(71, 230, 177);
}
.icon-guest-no-data-found .path6:before {
  content: "\ec3e";
  margin-left: -1.37890625em;
  color: rgb(166, 196, 247);
}
.icon-guest-no-data-found .path7:before {
  content: "\ec3f";
  margin-left: -1.37890625em;
  color: rgb(244, 230, 144);
}
.icon-guest-no-data-found .path8:before {
  content: "\ec40";
  margin-left: -1.37890625em;
  color: rgb(254, 235, 154);
}
.icon-guest-no-data-found .path9:before {
  content: "\ec41";
  margin-left: -1.37890625em;
  color: rgb(241, 170, 152);
}
.icon-guest-no-data-found .path10:before {
  content: "\ec42";
  margin-left: -1.37890625em;
  color: rgb(244, 170, 157);
}
.icon-guest-no-data-found .path11:before {
  content: "\ec43";
  margin-left: -1.37890625em;
  color: rgb(241, 170, 152);
}
.icon-guest-no-data-found .path12:before {
  content: "\ec44";
  margin-left: -1.37890625em;
  color: rgb(156, 191, 237);
}
.icon-guest-no-data-found .path13:before {
  content: "\ec45";
  margin-left: -1.37890625em;
  color: rgb(153, 237, 206);
}
.icon-guest-no-data-found .path14:before {
  content: "\ec46";
  margin-left: -1.37890625em;
  color: rgb(228, 228, 228);
}
.icon-guest-no-data-found .path15:before {
  content: "\ec47";
  margin-left: -1.37890625em;
  color: rgb(255, 255, 255);
}
.icon-guest-no-data-found .path16:before {
  content: "\ec48";
  margin-left: -1.37890625em;
  color: rgb(67, 160, 71);
}
.icon-guest-no-data-found .path17:before {
  content: "\ec49";
  margin-left: -1.37890625em;
  color: rgb(67, 160, 71);
}
.icon-guest-no-data-found .path18:before {
  content: "\ec4a";
  margin-left: -1.37890625em;
  color: rgb(67, 160, 71);
}
.icon-guest-no-data-found .path19:before {
  content: "\ec4b";
  margin-left: -1.37890625em;
  color: rgb(189, 222, 190);
}
.icon-guest-no-data-found .path20:before {
  content: "\ec4c";
  margin-left: -1.37890625em;
  color: rgb(189, 222, 190);
}
.icon-guest-no-data-found .path21:before {
  content: "\ec4d";
  margin-left: -1.37890625em;
  color: rgb(189, 222, 190);
}
.icon-guest-no-data-found .path22:before {
  content: "\ec4e";
  margin-left: -1.37890625em;
  color: rgb(189, 222, 190);
}
.icon-guest-no-data-found .path23:before {
  content: "\ec4f";
  margin-left: -1.37890625em;
  color: rgb(189, 222, 190);
}
.icon-guest-no-data-found .path24:before {
  content: "\ec50";
  margin-left: -1.37890625em;
  color: rgb(189, 222, 190);
}
.icon-guest-no-data-found .path25:before {
  content: "\ec51";
  margin-left: -1.37890625em;
  color: rgb(67, 160, 71);
}
.icon-temp_hold:before {
  content: "\e99f";
  color: #f00;
}
.icon-no-teetime .path1:before {
  content: "\e9a0";
  color: rgb(217, 236, 218);
}
.icon-no-teetime .path2:before {
  content: "\e9a1";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path3:before {
  content: "\e9a2";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path4:before {
  content: "\e9a3";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path5:before {
  content: "\e9a4";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path6:before {
  content: "\e9a5";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path7:before {
  content: "\e9a6";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path8:before {
  content: "\e9a7";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path9:before {
  content: "\e9a8";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path10:before {
  content: "\e9a9";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path11:before {
  content: "\e9aa";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path12:before {
  content: "\e9ab";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path13:before {
  content: "\e9ac";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path14:before {
  content: "\e9ad";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path15:before {
  content: "\e9ae";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path16:before {
  content: "\e9af";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path17:before {
  content: "\e9b0";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path18:before {
  content: "\e9b1";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path19:before {
  content: "\e9b2";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path20:before {
  content: "\e9b3";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path21:before {
  content: "\e9b4";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path22:before {
  content: "\e9b5";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path23:before {
  content: "\e9b6";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path24:before {
  content: "\e9b7";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path25:before {
  content: "\e9b8";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path26:before {
  content: "\e9b9";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path27:before {
  content: "\e9ba";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path28:before {
  content: "\e9f9";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path29:before {
  content: "\ea1c";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path30:before {
  content: "\ea1d";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path31:before {
  content: "\ea1e";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path32:before {
  content: "\ea1f";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path33:before {
  content: "\ea20";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path34:before {
  content: "\ea21";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path35:before {
  content: "\ea22";
  margin-left: -1.4423828125em;
  color: rgb(68, 64, 83);
}
.icon-no-teetime .path36:before {
  content: "\ea23";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path37:before {
  content: "\ea24";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path38:before {
  content: "\ea25";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path39:before {
  content: "\ea26";
  margin-left: -1.4423828125em;
  color: rgb(63, 61, 86);
}
.icon-no-teetime .path40:before {
  content: "\ea29";
  margin-left: -1.4423828125em;
  color: rgb(63, 61, 86);
}
.icon-no-teetime .path41:before {
  content: "\ea2a";
  margin-left: -1.4423828125em;
  color: rgb(63, 61, 86);
}
.icon-no-teetime .path42:before {
  content: "\ea2b";
  margin-left: -1.4423828125em;
  color: rgb(63, 61, 86);
}
.icon-no-teetime .path43:before {
  content: "\ea2c";
  margin-left: -1.4423828125em;
  color: rgb(63, 61, 86);
}
.icon-no-teetime .path44:before {
  content: "\ea2d";
  margin-left: -1.4423828125em;
  color: rgb(63, 61, 86);
}
.icon-no-teetime .path45:before {
  content: "\ea2e";
  margin-left: -1.4423828125em;
  color: rgb(63, 61, 86);
}
.icon-no-teetime .path46:before {
  content: "\ea2f";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path47:before {
  content: "\ea30";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path48:before {
  content: "\ea31";
  margin-left: -1.4423828125em;
  color: rgb(255, 255, 255);
}
.icon-no-teetime .path49:before {
  content: "\ea32";
  margin-left: -1.4423828125em;
  color: rgb(229, 229, 229);
}
.icon-no-teetime .path50:before {
  content: "\ea33";
  margin-left: -1.4423828125em;
  color: rgb(229, 229, 229);
}
.icon-no-teetime .path51:before {
  content: "\ea34";
  margin-left: -1.4423828125em;
  color: rgb(229, 229, 229);
}
.icon-no-teetime .path52:before {
  content: "\ea35";
  margin-left: -1.4423828125em;
  color: rgb(229, 229, 229);
}
.icon-no-teetime .path53:before {
  content: "\ea36";
  margin-left: -1.4423828125em;
  color: rgb(229, 229, 229);
}
.icon-no-teetime .path54:before {
  content: "\ea37";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path55:before {
  content: "\ea38";
  margin-left: -1.4423828125em;
  color: rgb(229, 229, 229);
}
.icon-no-teetime .path56:before {
  content: "\ea39";
  margin-left: -1.4423828125em;
  color: rgb(229, 229, 229);
}
.icon-no-teetime .path57:before {
  content: "\ea3a";
  margin-left: -1.4423828125em;
  color: rgb(229, 229, 229);
}
.icon-no-teetime .path58:before {
  content: "\ea3b";
  margin-left: -1.4423828125em;
  color: rgb(67, 160, 71);
}
.icon-no-teetime .path59:before {
  content: "\ea3c";
  margin-left: -1.4423828125em;
  color: rgb(56, 123, 59);
}
.icon-no-teetime .path60:before {
  content: "\ea40";
  margin-left: -1.4423828125em;
  color: rgb(56, 123, 59);
}
.icon-no-schedule-found .path1:before {
  content: "\ea41";
  color: rgb(217, 217, 217);
}
.icon-no-schedule-found .path2:before {
  content: "\ebe5";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path3:before {
  content: "\ebe6";
  margin-left: -1.267578125em;
  color: rgb(67, 160, 71);
}
.icon-no-schedule-found .path4:before {
  content: "\ebe7";
  margin-left: -1.267578125em;
  color: rgb(242, 242, 242);
}
.icon-no-schedule-found .path5:before {
  content: "\ebe8";
  margin-left: -1.267578125em;
  color: rgb(160, 207, 162);
}
.icon-no-schedule-found .path6:before {
  content: "\ebe9";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path7:before {
  content: "\ebea";
  margin-left: -1.267578125em;
  color: rgb(217, 236, 218);
}
.icon-no-schedule-found .path8:before {
  content: "\ebeb";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path9:before {
  content: "\ebec";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path10:before {
  content: "\ebed";
  margin-left: -1.267578125em;
  color: rgb(160, 207, 162);
}
.icon-no-schedule-found .path11:before {
  content: "\ebee";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path12:before {
  content: "\ebef";
  margin-left: -1.267578125em;
  color: rgb(217, 236, 218);
}
.icon-no-schedule-found .path13:before {
  content: "\ebf0";
  margin-left: -1.267578125em;
  color: rgb(217, 236, 218);
}
.icon-no-schedule-found .path14:before {
  content: "\ebf1";
  margin-left: -1.267578125em;
  color: rgb(217, 236, 218);
}
.icon-no-schedule-found .path15:before {
  content: "\ebf2";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path16:before {
  content: "\ebf3";
  margin-left: -1.267578125em;
  color: rgb(160, 207, 162);
}
.icon-no-schedule-found .path17:before {
  content: "\ebf4";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path18:before {
  content: "\ebf5";
  margin-left: -1.267578125em;
  color: rgb(217, 236, 218);
}
.icon-no-schedule-found .path19:before {
  content: "\ebf6";
  margin-left: -1.267578125em;
  color: rgb(160, 207, 162);
}
.icon-no-schedule-found .path20:before {
  content: "\ebf7";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path21:before {
  content: "\ebf8";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path22:before {
  content: "\ebf9";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path23:before {
  content: "\ebfa";
  margin-left: -1.267578125em;
  color: rgb(217, 236, 218);
}
.icon-no-schedule-found .path24:before {
  content: "\ebfb";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path25:before {
  content: "\ebfc";
  margin-left: -1.267578125em;
  color: rgb(217, 236, 218);
}
.icon-no-schedule-found .path26:before {
  content: "\ebfd";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path27:before {
  content: "\ebfe";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path28:before {
  content: "\ebff";
  margin-left: -1.267578125em;
  color: rgb(217, 236, 218);
}
.icon-no-schedule-found .path29:before {
  content: "\ec00";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path30:before {
  content: "\ec01";
  margin-left: -1.267578125em;
  color: rgb(160, 207, 162);
}
.icon-no-schedule-found .path31:before {
  content: "\ec02";
  margin-left: -1.267578125em;
  color: rgb(160, 207, 162);
}
.icon-no-schedule-found .path32:before {
  content: "\ec03";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path33:before {
  content: "\ec04";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path34:before {
  content: "\ec05";
  margin-left: -1.267578125em;
  color: rgb(160, 207, 162);
}
.icon-no-schedule-found .path35:before {
  content: "\ec06";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path36:before {
  content: "\ec07";
  margin-left: -1.267578125em;
  color: rgb(160, 207, 162);
}
.icon-no-schedule-found .path37:before {
  content: "\ec08";
  margin-left: -1.267578125em;
  color: rgb(160, 207, 162);
}
.icon-no-schedule-found .path38:before {
  content: "\ec09";
  margin-left: -1.267578125em;
  color: rgb(217, 236, 218);
}
.icon-no-schedule-found .path39:before {
  content: "\ec0a";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path40:before {
  content: "\ec0b";
  margin-left: -1.267578125em;
  color: rgb(160, 207, 162);
}
.icon-no-schedule-found .path41:before {
  content: "\ec0c";
  margin-left: -1.267578125em;
  color: rgb(217, 236, 218);
}
.icon-no-schedule-found .path42:before {
  content: "\ec0d";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path43:before {
  content: "\ec0e";
  margin-left: -1.267578125em;
  color: rgb(217, 236, 218);
}
.icon-no-schedule-found .path44:before {
  content: "\ec0f";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path45:before {
  content: "\ec10";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path46:before {
  content: "\ec11";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path47:before {
  content: "\ec12";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path48:before {
  content: "\ec13";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path49:before {
  content: "\ec14";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path50:before {
  content: "\ec15";
  margin-left: -1.267578125em;
  color: rgb(67, 160, 71);
  opacity: 0.5;
}
.icon-no-schedule-found .path51:before {
  content: "\ec16";
  margin-left: -1.267578125em;
  color: rgb(67, 160, 71);
  opacity: 0.2;
}
.icon-no-schedule-found .path52:before {
  content: "\ec17";
  margin-left: -1.267578125em;
  color: rgb(242, 242, 242);
}
.icon-no-schedule-found .path53:before {
  content: "\ec18";
  margin-left: -1.267578125em;
  color: rgb(67, 160, 71);
}
.icon-no-schedule-found .path54:before {
  content: "\ec19";
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
}
.icon-no-schedule-found .path55:before {
  content: "\ec1a";
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}
.icon-no-schedule-found .path56:before {
  content: "\ec1b";
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}
.icon-no-schedule-found .path57:before {
  content: "\ec1c";
  margin-left: -1.267578125em;
  color: rgb(33, 150, 243);
}
.icon-no-schedule-found .path58:before {
  content: "\ec1d";
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}
.icon-no-schedule-found .path59:before {
  content: "\ec1e";
  margin-left: -1.267578125em;
  color: rgb(33, 150, 243);
}
.icon-no-schedule-found .path60:before {
  content: "\ec1f";
  margin-left: -1.267578125em;
  color: rgb(67, 160, 71);
}
.icon-no-schedule-found .path61:before {
  content: "\ec20";
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}
.icon-no-schedule-found .path62:before {
  content: "\ec21";
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}
.icon-no-schedule-found .path63:before {
  content: "\ec22";
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}
.icon-no-schedule-found .path64:before {
  content: "\ec23";
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}
.icon-no-schedule-found .path65:before {
  content: "\ec24";
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}
.icon-no-schedule-found .path66:before {
  content: "\ec25";
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}
.icon-no-schedule-found .path67:before {
  content: "\ec26";
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}
.icon-no-schedule-found .path68:before {
  content: "\ec27";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path69:before {
  content: "\ec28";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path70:before {
  content: "\ec29";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path71:before {
  content: "\ec2a";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path72:before {
  content: "\ec2b";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path73:before {
  content: "\ec2c";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path74:before {
  content: "\ec2d";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path75:before {
  content: "\ec2e";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path76:before {
  content: "\ec2f";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path77:before {
  content: "\ec30";
  margin-left: -1.267578125em;
  color: rgb(217, 236, 218);
}
.icon-no-schedule-found .path78:before {
  content: "\ec31";
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}
.icon-no-schedule-found .path79:before {
  content: "\ec32";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path80:before {
  content: "\ec33";
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}
.icon-no-schedule-found .path81:before {
  content: "\ec34";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path82:before {
  content: "\ec35";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-no-schedule-found .path83:before {
  content: "\ec36";
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}
.icon-no-schedule-found .path84:before {
  content: "\ec37";
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}
.icon-no-schedule-found .path85:before {
  content: "\ec38";
  margin-left: -1.267578125em;
  color: rgb(105, 240, 174);
}
.icon-No-Search-Results-2 .path1:before {
  content: "\e986";
  color: rgb(235, 244, 235);
}
.icon-No-Search-Results-2 .path2:before {
  content: "\e987";
  margin-left: -1.404296875em;
  color: rgb(77, 138, 240);
}
.icon-No-Search-Results-2 .path3:before {
  content: "\e988";
  margin-left: -1.404296875em;
  color: rgb(253, 216, 53);
}
.icon-No-Search-Results-2 .path4:before {
  content: "\e989";
  margin-left: -1.404296875em;
  color: rgb(71, 230, 177);
  opacity: 0.5;
}
.icon-No-Search-Results-2 .path5:before {
  content: "\e98a";
  margin-left: -1.404296875em;
  color: rgb(245, 95, 68);
}
.icon-No-Search-Results-2 .path6:before {
  content: "\e98b";
  margin-left: -1.404296875em;
  color: rgb(215, 215, 215);
  opacity: 0.5;
}
.icon-No-Search-Results-2 .path7:before {
  content: "\e98c";
  margin-left: -1.404296875em;
  color: rgb(255, 255, 255);
}
.icon-No-Search-Results-2 .path8:before {
  content: "\e98d";
  margin-left: -1.404296875em;
  color: rgb(0, 0, 0);
}
.icon-No-Search-Results-2 .path9:before {
  content: "\e98e";
  margin-left: -1.404296875em;
  color: rgb(255, 255, 255);
}
.icon-No-Search-Results-2 .path10:before {
  content: "\e98f";
  margin-left: -1.404296875em;
  color: rgb(189, 189, 189);
}
.icon-No-Search-Results-2 .path11:before {
  content: "\e990";
  margin-left: -1.404296875em;
  color: rgb(224, 224, 224);
}
.icon-No-Search-Results-2 .path12:before {
  content: "\e991";
  margin-left: -1.404296875em;
  color: rgb(67, 160, 71);
  opacity: 0.9;
}
.icon-No-Search-Results-2 .path13:before {
  content: "\e992";
  margin-left: -1.404296875em;
  color: rgb(0, 0, 0);
}
.icon-No-Search-Results-2 .path14:before {
  content: "\e993";
  margin-left: -1.404296875em;
  color: rgb(255, 255, 255);
}
.icon-No-Search-Results-2 .path15:before {
  content: "\e994";
  margin-left: -1.404296875em;
  color: rgb(189, 189, 189);
}
.icon-No-Search-Results-2 .path16:before {
  content: "\e995";
  margin-left: -1.404296875em;
  color: rgb(224, 224, 224);
}
.icon-No-Search-Results-2 .path17:before {
  content: "\e996";
  margin-left: -1.404296875em;
  color: rgb(67, 160, 71);
  opacity: 0.9;
}
.icon-No-Search-Results-2 .path18:before {
  content: "\e997";
  margin-left: -1.404296875em;
  color: rgb(0, 0, 0);
}
.icon-No-Search-Results-2 .path19:before {
  content: "\e998";
  margin-left: -1.404296875em;
  color: rgb(255, 255, 255);
}
.icon-No-Search-Results-2 .path20:before {
  content: "\e999";
  margin-left: -1.404296875em;
  color: rgb(189, 189, 189);
}
.icon-No-Search-Results-2 .path21:before {
  content: "\e99a";
  margin-left: -1.404296875em;
  color: rgb(224, 224, 224);
}
.icon-No-Search-Results-2 .path22:before {
  content: "\e99b";
  margin-left: -1.404296875em;
  color: rgb(224, 224, 224);
}
.icon-No-Search-Results-2 .path23:before {
  content: "\e99c";
  margin-left: -1.404296875em;
  color: rgb(67, 160, 71);
  opacity: 0.9;
}
.icon-No-Search-Results-2 .path24:before {
  content: "\e99d";
  margin-left: -1.404296875em;
  color: rgb(224, 224, 224);
}
.icon-No-Search-Results-2 .path25:before {
  content: "\e99e";
  margin-left: -1.404296875em;
  color: rgb(67, 160, 71);
}
.icon-No-Search-Results .path1:before {
  content: "\e979";
  color: rgb(235, 244, 235);
  opacity: 0.997;
}
.icon-No-Search-Results .path2:before {
  content: "\e97a";
  margin-left: -1.37890625em;
  color: rgb(224, 224, 224);
}
.icon-No-Search-Results .path3:before {
  content: "\e97b";
  margin-left: -1.37890625em;
  color: rgb(255, 255, 255);
}
.icon-No-Search-Results .path4:before {
  content: "\e97c";
  margin-left: -1.37890625em;
  color: rgb(67, 160, 71);
}
.icon-No-Search-Results .path5:before {
  content: "\e97d";
  margin-left: -1.37890625em;
  color: rgb(189, 222, 190);
}
.icon-No-Search-Results .path6:before {
  content: "\e97e";
  margin-left: -1.37890625em;
  color: rgb(224, 224, 224);
}
.icon-No-Search-Results .path7:before {
  content: "\e97f";
  margin-left: -1.37890625em;
  color: rgb(255, 255, 255);
}
.icon-No-Search-Results .path8:before {
  content: "\e980";
  margin-left: -1.37890625em;
  color: rgb(67, 160, 71);
}
.icon-No-Search-Results .path9:before {
  content: "\e981";
  margin-left: -1.37890625em;
  color: rgb(189, 222, 190);
}
.icon-No-Search-Results .path10:before {
  content: "\e982";
  margin-left: -1.37890625em;
  color: rgb(71, 230, 177);
}
.icon-No-Search-Results .path11:before {
  content: "\e983";
  margin-left: -1.37890625em;
  color: rgb(165, 203, 247);
}
.icon-No-Search-Results .path12:before {
  content: "\e984";
  margin-left: -1.37890625em;
  color: rgb(245, 95, 68);
  opacity: 0.5;
}
.icon-No-Search-Results .path13:before {
  content: "\ea27";
  margin-left: -1.37890625em;
  color: rgb(165, 196, 247);
}
.icon-No-Search-Results .path14:before {
  content: "\ea28";
  margin-left: -1.37890625em;
  color: rgb(162, 242, 216);
}
.icon-refund:before {
  content: "\ebe3";
  color: #1da664;
}
.icon-rain-check:before {
  content: "\ebe4";
}
.icon-arrow-pointing-to-right:before {
  content: "\ebd2";
}
.icon-dashboard-calendar:before {
  content: "\ebd3";
  color: #434a54;
}
.icon-charts:before {
  content: "\ebd4";
  color: #f25b5b;
}
.icon-dashboard-clock:before {
  content: "\ebd5";
  color: #434a54;
}
.icon-dashboard-course:before {
  content: "\ebd6";
  color: #e84949;
}
.icon-dashboard-cross:before {
  content: "\ebd7";
}
.icon-dashboard-down:before {
  content: "\ebd8";
  color: #abb7b7;
}
.icon-dashboard-flag:before {
  content: "\ebd9";
  color: #2fb2f7;
}
.icon-hamburger:before {
  content: "\ebda";
  color: #707070;
}
.icon-dashboard-lessons:before {
  content: "\ebdb";
  color: #1fb29c;
}
.icon-Lost-and-Found:before {
  content: "\ebdc";
}
.icon-new-user:before {
  content: "\ebdd";
}
.icon-revenue2:before {
  content: "\ebde";
  color: #f88b2d;
}
.icon-dashboard-arrow-left:before {
  content: "\ebdf";
}
.icon-swap1:before {
  content: "\ebe0";
}
.icon-dashboard-tick:before {
  content: "\ebe1";
}
.icon-dashboard-waitlist:before {
  content: "\ebe2";
  color: #ffa900;
}
.icon-cloudy .path1:before {
  content: "\ea42";
  color: rgb(255, 216, 0);
}
.icon-cloudy .path2:before {
  content: "\ea43";
  margin-left: -1.4111328125em;
  color: rgb(255, 255, 255);
}
.icon-thunder .path1:before {
  content: "\ea44";
  color: rgb(255, 216, 59);
}
.icon-thunder .path2:before {
  content: "\ea45";
  margin-left: -1.0595703125em;
  color: rgb(0, 0, 0);
}
.icon-thunder .path3:before {
  content: "\ea46";
  margin-left: -1.0595703125em;
  color: rgb(0, 0, 0);
}
.icon-thunder .path4:before {
  content: "\ea47";
  margin-left: -1.0595703125em;
  color: rgb(0, 0, 0);
}
.icon-light .path1:before {
  content: "\ea48";
  color: rgb(255, 216, 59);
}
.icon-light .path2:before {
  content: "\ea49";
  margin-left: -1.0595703125em;
  color: rgb(0, 0, 0);
}
.icon-light .path3:before {
  content: "\ea4a";
  margin-left: -1.0595703125em;
  color: rgb(0, 0, 0);
}
.icon-rainy:before {
  content: "\ebd0";
}
.icon-sunny:before {
  content: "\ebd1";
  color: #ffd800;
}
.icon-active-user:before {
  content: "\ea3d";
}
.icon-block-user:before {
  content: "\ea3e";
}
.icon-lessons:before {
  content: "\ea3f";
}
.icon-lost_found:before {
  content: "\e985";
}
.icon-no-appointment-booked .path1:before {
  content: "\ea4b";
  color: rgb(67, 160, 71);
  opacity: 0.1;
}
.icon-no-appointment-booked .path2:before {
  content: "\ea4c";
  margin-left: -1.494140625em;
  color: rgb(108, 99, 255);
}
.icon-no-appointment-booked .path3:before {
  content: "\ea4d";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path4:before {
  content: "\ea4e";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path5:before {
  content: "\ea4f";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path6:before {
  content: "\ea50";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path7:before {
  content: "\ea51";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path8:before {
  content: "\ea52";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path9:before {
  content: "\ea53";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path10:before {
  content: "\ea54";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path11:before {
  content: "\ea55";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path12:before {
  content: "\ea56";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path13:before {
  content: "\ea57";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path14:before {
  content: "\ea58";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path15:before {
  content: "\ea59";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path16:before {
  content: "\ea5a";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path17:before {
  content: "\ea5b";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path18:before {
  content: "\ea5c";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path19:before {
  content: "\ea5d";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path20:before {
  content: "\eba1";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path21:before {
  content: "\eba2";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path22:before {
  content: "\eba3";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path23:before {
  content: "\eba4";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path24:before {
  content: "\eba5";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path25:before {
  content: "\eba6";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path26:before {
  content: "\eba7";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path27:before {
  content: "\eba8";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path28:before {
  content: "\eba9";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path29:before {
  content: "\ebaa";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path30:before {
  content: "\ebab";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path31:before {
  content: "\ebac";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path32:before {
  content: "\ebad";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path33:before {
  content: "\ebae";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path34:before {
  content: "\ebaf";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.354;
}
.icon-no-appointment-booked .path35:before {
  content: "\ebb0";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
}
.icon-no-appointment-booked .path36:before {
  content: "\ebb1";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
}
.icon-no-appointment-booked .path37:before {
  content: "\ebb2";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
}
.icon-no-appointment-booked .path38:before {
  content: "\ebb3";
  margin-left: -1.494140625em;
  color: rgb(67, 160, 71);
}
.icon-no-appointment-booked .path39:before {
  content: "\ebb4";
  margin-left: -1.494140625em;
  color: rgb(63, 61, 86);
}
.icon-no-appointment-booked .path40:before {
  content: "\ebb5";
  margin-left: -1.494140625em;
  color: rgb(63, 61, 86);
}
.icon-no-appointment-booked .path41:before {
  content: "\ebb6";
  margin-left: -1.494140625em;
  color: rgb(63, 61, 86);
}
.icon-no-appointment-booked .path42:before {
  content: "\ebb7";
  margin-left: -1.494140625em;
  color: rgb(63, 61, 86);
}
.icon-no-appointment-booked .path43:before {
  content: "\ebb8";
  margin-left: -1.494140625em;
  color: rgb(63, 61, 86);
}
.icon-no-appointment-booked .path44:before {
  content: "\ebb9";
  margin-left: -1.494140625em;
  color: rgb(63, 61, 86);
}
.icon-no-appointment-booked .path45:before {
  content: "\ebba";
  margin-left: -1.494140625em;
  color: rgb(63, 61, 86);
}
.icon-no-appointment-booked .path46:before {
  content: "\ebbb";
  margin-left: -1.494140625em;
  color: rgb(67, 160, 71);
}
.icon-no-appointment-booked .path47:before {
  content: "\ebbc";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-no-appointment-booked .path48:before {
  content: "\ebbd";
  margin-left: -1.494140625em;
  color: rgb(255, 255, 255);
}
.icon-no-appointment-booked .path49:before {
  content: "\ebbe";
  margin-left: -1.494140625em;
  color: rgb(0, 0, 0);
  opacity: 0.1;
}
.icon-no-appointment-booked .path50:before {
  content: "\ebbf";
  margin-left: -1.494140625em;
  color: rgb(0, 0, 0);
  opacity: 0.1;
}
.icon-no-appointment-booked .path51:before {
  content: "\ebc0";
  margin-left: -1.494140625em;
  color: rgb(236, 235, 237);
  opacity: 0.343;
}
.icon-no-appointment-booked .path52:before {
  content: "\ebc1";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
  opacity: 0.1;
}
.icon-no-appointment-booked .path53:before {
  content: "\ebc2";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
  opacity: 0.1;
}
.icon-no-appointment-booked .path54:before {
  content: "\ebc3";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
  opacity: 0.1;
}
.icon-no-appointment-booked .path55:before {
  content: "\ebc4";
  margin-left: -1.494140625em;
  color: rgb(67, 160, 71);
}
.icon-no-appointment-booked .path56:before {
  content: "\ebc5";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
  opacity: 0.1;
}
.icon-no-appointment-booked .path57:before {
  content: "\ebc6";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
  opacity: 0.1;
}
.icon-no-appointment-booked .path58:before {
  content: "\ebc7";
  margin-left: -1.494140625em;
  color: rgb(68, 64, 83);
  opacity: 0.1;
}
.icon-no-appointment-booked .path59:before {
  content: "\ebc8";
  margin-left: -1.494140625em;
  color: rgb(67, 160, 71);
}
.icon-no-appointment-booked .path60:before {
  content: "\ebc9";
  margin-left: -1.494140625em;
  color: rgb(0, 0, 0);
  opacity: 0.1;
}
.icon-no-appointment-booked .path61:before {
  content: "\ebca";
  margin-left: -1.494140625em;
  color: rgb(0, 0, 0);
  opacity: 0.1;
}
.icon-no-appointment-booked .path62:before {
  content: "\ebcb";
  margin-left: -1.494140625em;
  color: rgb(67, 160, 71);
  opacity: 0.1;
}
.icon-no-appointment-booked .path63:before {
  content: "\ebcc";
  margin-left: -1.494140625em;
  color: rgb(67, 160, 71);
  opacity: 0.1;
}
.icon-no-appointment-booked .path64:before {
  content: "\ebcd";
  margin-left: -1.494140625em;
  color: rgb(67, 160, 71);
  opacity: 0.1;
}
.icon-no-appointment-booked .path65:before {
  content: "\ebce";
  margin-left: -1.494140625em;
  color: rgb(67, 160, 71);
  opacity: 0.1;
}
.icon-snow:before {
  content: "\ebcf";
}
.icon-clear-players:before {
  content: "\eb4f";
}
.icon-copy-to-many:before {
  content: "\eb50";
}
.icon-copy11:before {
  content: "\eb51";
}
.icon-course-flag:before {
  content: "\eb52";
}
.icon-delay:before {
  content: "\eb53";
}
.icon-frost-delay:before {
  content: "\eb54";
}
.icon-member11:before {
  content: "\eb55";
}
.icon-pay:before {
  content: "\eb59";
}
.icon-shop11:before {
  content: "\eb56";
}
.icon-course-hand:before {
  content: "\eb57";
}
.icon-bag-number:before {
  content: "\eb4a";
}
.icon-cancel-players:before {
  content: "\eb4b";
}
.icon-hold-tee-time:before {
  content: "\eb4c";
}
.icon-print11:before {
  content: "\eb4d";
}
.icon-rate-circle:before {
  content: "\eb58";
}
.icon-save:before {
  content: "\eb4e";
}
.icon-time-delay:before {
  content: "\eb44";
}
.icon-hold:before {
  content: "\eb45";
}
.icon-sqeeze:before {
  content: "\eb46";
}
.icon-edit-new:before {
  content: "\eb40";
}
.icon-Grey:before {
  content: "\eb3e";
  color: #9b9b9b;
}
.icon-rate:before {
  content: "\eb3f";
}
.icon-Circle:before {
  content: "\eb3c";
  color: #fff;
}
.icon-Searchbar:before {
  content: "\eb3d";
}
.icon-notification:before {
  content: "\eb3b";
}
.icon-filled-more:before {
  content: "\eb3a";
}
.icon-Audit:before {
  content: "\eb39";
}
.icon-settings2:before {
  content: "\eb38";
}
.icon-reports:before {
  content: "\eb37";
}
.icon-shop1:before {
  content: "\eb36";
}
.icon-guest:before {
  content: "\eb35";
}
.icon-tee-time:before {
  content: "\eb34";
}
.icon-home:before {
  content: "\eb33";
}
.icon-device:before {
  content: "\eb2f";
}
.icon-card-type:before {
  content: "\eb30";
}
.icon-square:before {
  content: "\eb47";
}
.icon-left-dashboard-arrow:before {
  content: "\eb31";
}
.icon-right-dashboard-arrow:before {
  content: "\eb32";
}
.icon-plus:before {
  content: "\eb2d";
}
.icon-minus:before {
  content: "\eb2e";
}
.icon-percent1:before {
  content: "\e9fa";
}
.icon-audit1:before {
  content: "\eb29";
}
.icon-location-login1:before {
  content: "\eb25";
}
.icon-search:before {
  content: "\eb24";
}
.icon-phone:before {
  content: "\eb1f";
}
.icon-scheduled1:before {
  content: "\eb1e";
}
.icon-delete:before {
  content: "\eb19";
}
.icon-others:before {
  content: "\eb18";
}
.icon-mail1:before {
  content: "\eb14";
}
.icon-cash:before {
  content: "\eb13";
}
.icon-card:before {
  content: "\eb11";
}
.icon-calendar:before {
  content: "\eb42";
}
.icon-cheque:before {
  content: "\eb0b";
}
.icon-giftcard:before {
  content: "\eb0a";
}
.icon-bell:before {
  content: "\eb05";
}
.icon-Cancel1:before {
  content: "\eb02";
}
.icon-coins:before {
  content: "\eb03";
}
.icon-edit:before {
  content: "\eb2a";
}
.icon-drag-drop:before {
  content: "\eb26";
}
.icon-confirmed1:before {
  content: "\eb23";
}
.icon-down-arrow:before {
  content: "\eb20";
}
.icon-up-arrow1:before {
  content: "\eb1d";
}
.icon-uparrow:before {
  content: "\eb1a";
}
.icon-more:before {
  content: "\eb48";
}
.icon-mug1:before {
  content: "\eb15";
}
.icon-redo1:before {
  content: "\eb12";
}
.icon-Group-1971:before {
  content: "\eb10";
}
.icon-left-arrow2:before {
  content: "\eb0c";
}
.icon-right-arrow2:before {
  content: "\eb09";
}
.icon-clock1:before {
  content: "\eb06";
}
.icon-revenue1:before {
  content: "\eb01";
}
.icon-items1:before {
  content: "\eb04";
}
.icon-lunch1:before {
  content: "\eb2c";
}
.icon-add1:before {
  content: "\eb2b";
}
.icon-print1:before {
  content: "\eb28";
}
.icon-recurring1:before {
  content: "\eb27";
}
.icon-undo1:before {
  content: "\eb22";
}
.icon-link1:before {
  content: "\eb21";
}
.icon-recap1:before {
  content: "\eb1c";
}
.icon-multi-appointments1:before {
  content: "\eb1b";
}
.icon-spa-package1:before {
  content: "\eb17";
}
.icon-donot-move:before {
  content: "\eb16";
}
.icon-paid1:before {
  content: "\eb0e";
}
.icon-reset:before {
  content: "\eb0f";
}
.icon-request-staff1:before {
  content: "\eb0d";
}
.icon-addons1:before {
  content: "\eb08";
}
.icon-comments1:before {
  content: "\eb07";
}
.icon-adddeposit1:before {
  content: "\eb00";
}
.icon-hotel-guest1:before {
  content: "\eaff";
}
.icon-member1:before {
  content: "\ea1b";
}
.icon-vip1:before {
  content: "\eaee";
}
.icon-male-staff1:before {
  content: "\eaef";
}
.icon-female-staff1:before {
  content: "\eaf0";
}
.icon-transaction1:before {
  content: "\eaf1";
}
.icon-cancel1:before {
  content: "\eaf2";
}
.icon-enforce-gender1:before {
  content: "\eaf3";
}
.icon-overbook1:before {
  content: "\eaf4";
}
.icon-no-staff1:before {
  content: "\eaf5";
}
.icon-move1:before {
  content: "\eaf6";
}
.icon-copy1:before {
  content: "\eaf7";
}
.icon-changeservice1:before {
  content: "\eaf8";
}
.icon-zoom-in2:before {
  content: "\eafa";
}
.icon-legends1:before {
  content: "\eaf9";
}
.icon-Revenue1:before {
  content: "\eafb";
}
.icon-Item1:before {
  content: "\eafc";
}
.icon-zoom-out2:before {
  content: "\eafd";
}
.icon-user1:before {
  content: "\eafe";
}
.icon-sendmail1:before {
  content: "\ea1a";
}
.icon-hair1:before {
  content: "\ea19";
}
.icon-wax1:before {
  content: "\ea17";
}
.icon-face1:before {
  content: "\ea18";
}
.icon-body1:before {
  content: "\ea16";
}
.icon-filter_filled1:before {
  content: "\ea15";
}
.icon-information1:before {
  content: "\ea14";
}
.icon-note1:before {
  content: "\ea13";
}
.icon-done1:before {
  content: "\ea12";
}
.icon-checkout1:before {
  content: "\ea11";
}
.icon-checkin1:before {
  content: "\ea10";
}
.icon-disabled-user1:before {
  content: "\ea07";
  color: #a73535;
}
.icon-enabled-user1:before {
  content: "\ea08";
  color: #56a948;
}
.icon-password-hide1:before {
  content: "\ea09";
}
.icon-therapists1:before {
  content: "\ea0a";
}
.icon-woman1:before {
  content: "\ea0b";
}
.icon-repeatclients1:before {
  content: "\ea0d";
}
.icon-newclients1:before {
  content: "\ea0c";
}
.icon-noshows1:before {
  content: "\ea0e";
}
.icon-alert1:before {
  content: "\ea0f";
}
.icon-servicecharge1:before {
  content: "\ea06";
}
.icon-gratuity1:before {
  content: "\ea05";
}
.icon-hash1:before {
  content: "\ea04";
}
.icon-warning-info-icon1:before {
  content: "\ea03";
}
.icon-password-show1:before {
  content: "\ea02";
}
.icon-password1:before {
  content: "\ea01";
}
.icon-left-arrow-dashboard1:before {
  content: "\ea00";
}
.icon-right-arrow-dashboard1:before {
  content: "\e9fd";
}
.icon-sort-arrow:before {
  content: "\e9fe";
  color: #6b6b6b;
}
.icon-filled-up-arrow:before {
  content: "\e9ff";
  color: #6b6b6b;
}
.icon-zoom-out11:before {
  content: "\e9fb";
}
.icon-zoom-in11:before {
  content: "\e9fc";
}
.icon-player:before {
  content: "\eb41";
}
.icon-visa1 .path1:before {
  content: "\e9f8";
  color: rgb(0, 88, 160);
}
.icon-visa1 .path2:before {
  content: "\eb5a";
  margin-left: -1.5009765625em;
  color: rgb(255, 255, 255);
}
.icon-visa1 .path3:before {
  content: "\eb5b";
  margin-left: -1.5009765625em;
  color: rgb(0, 88, 160);
}
.icon-visa1 .path4:before {
  content: "\eb5c";
  margin-left: -1.5009765625em;
  color: rgb(0, 88, 160);
}
.icon-visa1 .path5:before {
  content: "\eb5d";
  margin-left: -1.5009765625em;
  color: rgb(0, 88, 160);
}
.icon-visa1 .path6:before {
  content: "\eb5e";
  margin-left: -1.5009765625em;
  color: rgb(0, 88, 160);
}
.icon-visa1 .path7:before {
  content: "\eb5f";
  margin-left: -1.5009765625em;
  color: rgb(250, 166, 26);
}
.icon-advance-search1:before {
  content: "\eb43";
}
.icon-No_records_found_aftersearch1 .path1:before {
  content: "\eb49";
  color: rgb(197, 141, 11);
  opacity: 0.2040;
}
.icon-No_records_found_aftersearch1 .path2:before {
  content: "\eb60";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch1 .path3:before {
  content: "\eb61";
  margin-left: -1.4921875em;
  color: rgb(108, 99, 255);
  opacity: 0.7;
}
.icon-No_records_found_aftersearch1 .path4:before {
  content: "\eb62";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch1 .path5:before {
  content: "\eb63";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch1 .path6:before {
  content: "\eb64";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch1 .path7:before {
  content: "\eb65";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch1 .path8:before {
  content: "\eb66";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch1 .path9:before {
  content: "\eb67";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch1 .path10:before {
  content: "\eb68";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch1 .path11:before {
  content: "\eb69";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}
.icon-No_records_found_aftersearch1 .path12:before {
  content: "\eb6a";
  margin-left: -1.4921875em;
  color: rgb(245, 245, 245);
}
.icon-No_records_found_aftersearch1 .path13:before {
  content: "\eb6b";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}
.icon-No_records_found_aftersearch1 .path14:before {
  content: "\eb6c";
  margin-left: -1.4921875em;
  color: rgb(255, 255, 255);
}
.icon-No_records_found_aftersearch1 .path15:before {
  content: "\eb6d";
  margin-left: -1.4921875em;
  color: rgb(108, 99, 255);
  opacity: 0.4;
}
.icon-No_records_found_aftersearch1 .path16:before {
  content: "\eb6e";
  margin-left: -1.4921875em;
  color: rgb(108, 99, 255);
  opacity: 0.4;
}
.icon-No_records_found_aftersearch1 .path17:before {
  content: "\eb6f";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}
.icon-No_records_found_aftersearch1 .path18:before {
  content: "\eb70";
  margin-left: -1.4921875em;
  color: rgb(245, 245, 245);
}
.icon-No_records_found_aftersearch1 .path19:before {
  content: "\eb71";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}
.icon-No_records_found_aftersearch1 .path20:before {
  content: "\eb72";
  margin-left: -1.4921875em;
  color: rgb(255, 255, 255);
}
.icon-No_records_found_aftersearch1 .path21:before {
  content: "\eb73";
  margin-left: -1.4921875em;
  color: rgb(189, 189, 189);
  opacity: 0.4;
}
.icon-No_records_found_aftersearch1 .path22:before {
  content: "\eb74";
  margin-left: -1.4921875em;
  color: rgb(189, 189, 189);
  opacity: 0.4;
}
.icon-No_records_found_aftersearch1 .path23:before {
  content: "\eb75";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}
.icon-No_records_found_aftersearch1 .path24:before {
  content: "\eb76";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}
.icon-No_records_found_aftersearch1 .path25:before {
  content: "\eb77";
  margin-left: -1.4921875em;
  color: rgb(255, 255, 255);
}
.icon-No_records_found_aftersearch1 .path26:before {
  content: "\eb78";
  margin-left: -1.4921875em;
  color: rgb(255, 255, 255);
}
.icon-No_records_found_aftersearch1 .path27:before {
  content: "\eb79";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
}
.icon-No_records_found_aftersearch1 .path28:before {
  content: "\eb7a";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
}
.icon-No_records_found_aftersearch1 .path29:before {
  content: "\eb7b";
  margin-left: -1.4921875em;
  color: rgb(255, 255, 255);
}
.icon-No_records_found_aftersearch1 .path30:before {
  content: "\eb7c";
  margin-left: -1.4921875em;
  color: rgb(189, 189, 189);
}
.icon-No_records_found_aftersearch1 .path31:before {
  content: "\eb7d";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch1 .path32:before {
  content: "\eb7e";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch1 .path33:before {
  content: "\eb7f";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch1 .path34:before {
  content: "\eb80";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch1 .path35:before {
  content: "\eb81";
  margin-left: -1.4921875em;
  color: rgb(197, 141, 11);
  opacity: 0.9;
}
.icon-No_records_found_aftersearch1 .path36:before {
  content: "\eb82";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
}
.icon-No_records_found_aftersearch1 .path37:before {
  content: "\eb83";
  margin-left: -1.4921875em;
  color: rgb(255, 255, 255);
}
.icon-No_records_found_aftersearch1 .path38:before {
  content: "\eb84";
  margin-left: -1.4921875em;
  color: rgb(189, 189, 189);
}
.icon-No_records_found_aftersearch1 .path39:before {
  content: "\eb85";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch1 .path40:before {
  content: "\eb86";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch1 .path41:before {
  content: "\eb87";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch1 .path42:before {
  content: "\eb88";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch1 .path43:before {
  content: "\eb89";
  margin-left: -1.4921875em;
  color: rgb(197, 141, 11);
  opacity: 0.9;
}
.icon-No_records_found_aftersearch1 .path44:before {
  content: "\eb8a";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
}
.icon-No_records_found_aftersearch1 .path45:before {
  content: "\eb8b";
  margin-left: -1.4921875em;
  color: rgb(255, 255, 255);
}
.icon-No_records_found_aftersearch1 .path46:before {
  content: "\eb8c";
  margin-left: -1.4921875em;
  color: rgb(189, 189, 189);
}
.icon-No_records_found_aftersearch1 .path47:before {
  content: "\eb8d";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch1 .path48:before {
  content: "\eb8e";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch1 .path49:before {
  content: "\eb8f";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch1 .path50:before {
  content: "\eb90";
  margin-left: -1.4921875em;
  color: rgb(224, 224, 224);
}
.icon-No_records_found_aftersearch1 .path51:before {
  content: "\eb91";
  margin-left: -1.4921875em;
  color: rgb(197, 141, 11);
  opacity: 0.9;
}
.icon-No_records_found_aftersearch1 .path52:before {
  content: "\eb92";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
}
.icon-No_records_found_aftersearch1 .path53:before {
  content: "\eb93";
  margin-left: -1.4921875em;
  color: rgb(0, 0, 0);
}
.icon-No_records_found_aftersearch1 .path54:before {
  content: "\eb94";
  margin-left: -1.4921875em;
  color: rgb(197, 141, 11);
}
.icon-No_records_found_aftersearch1 .path55:before {
  content: "\eb95";
  margin-left: -1.4921875em;
  color: rgb(197, 141, 11);
}
.icon-No_records_found_aftersearch1 .path56:before {
  content: "\eb96";
  margin-left: -1.4921875em;
  color: rgb(197, 141, 11);
}
.icon-No_records_found_aftersearch1 .path57:before {
  content: "\eb97";
  margin-left: -1.4921875em;
  color: rgb(71, 230, 177);
}
.icon-No_records_found_aftersearch1 .path58:before {
  content: "\eb98";
  margin-left: -1.4921875em;
  color: rgb(71, 230, 177);
}
.icon-No_records_found_aftersearch1 .path59:before {
  content: "\eb99";
  margin-left: -1.4921875em;
  color: rgb(77, 138, 240);
}
.icon-No_records_found_aftersearch1 .path60:before {
  content: "\eb9a";
  margin-left: -1.4921875em;
  color: rgb(253, 216, 53);
}
.icon-No_records_found_aftersearch1 .path61:before {
  content: "\eb9b";
  margin-left: -1.4921875em;
  color: rgb(253, 216, 53);
}
.icon-No_records_found_aftersearch1 .path62:before {
  content: "\eb9c";
  margin-left: -1.4921875em;
  color: rgb(245, 95, 68);
}
.icon-No_records_found_aftersearch1 .path63:before {
  content: "\eb9d";
  margin-left: -1.4921875em;
  color: rgb(245, 95, 68);
}
.icon-No_records_found_aftersearch1 .path64:before {
  content: "\eb9e";
  margin-left: -1.4921875em;
  color: rgb(77, 138, 240);
}
.icon-No_records_found_aftersearch1 .path65:before {
  content: "\eb9f";
  margin-left: -1.4921875em;
  color: rgb(71, 230, 177);
  opacity: 0.5;
}
.icon-No_records_found_aftersearch1 .path66:before {
  content: "\eba0";
  margin-left: -1.4921875em;
  color: rgb(77, 138, 240);
}
.icon-JCB .path1:before {
  content: "\ea6f";
  color: rgb(15, 84, 157);
}
.icon-JCB .path2:before {
  content: "\ea70";
  margin-left: -1.453125em;
  color: rgb(180, 31, 54);
}
.icon-JCB .path3:before {
  content: "\ea71";
  margin-left: -1.453125em;
  color: rgb(50, 153, 71);
}
.icon-JCB .path4:before {
  content: "\ea72";
  margin-left: -1.453125em;
  color: rgb(243, 242, 242);
}
.icon-JCB .path5:before {
  content: "\ea73";
  margin-left: -1.453125em;
  color: rgb(243, 242, 242);
}
.icon-JCB .path6:before {
  content: "\ea78";
  margin-left: -1.453125em;
  color: rgb(243, 242, 242);
}
.icon-AMEX .path1:before {
  content: "\ea79";
  color: rgb(48, 111, 197);
}
.icon-AMEX .path2:before {
  content: "\ea7a";
  margin-left: -1.4501953125em;
  color: rgb(255, 255, 255);
}
.icon-AMEX .path3:before {
  content: "\ea7b";
  margin-left: -1.4501953125em;
  color: rgb(255, 255, 255);
}
.icon-AMEX .path4:before {
  content: "\eae7";
  margin-left: -1.4501953125em;
  color: rgb(255, 255, 255);
}
.icon-AMEX .path5:before {
  content: "\eae8";
  margin-left: -1.4501953125em;
  color: rgb(255, 255, 255);
}
.icon-AMEX .path6:before {
  content: "\eae9";
  margin-left: -1.4501953125em;
  color: rgb(255, 255, 255);
}
.icon-AMEX .path7:before {
  content: "\eaea";
  margin-left: -1.4501953125em;
  color: rgb(255, 255, 255);
}
.icon-AMEX .path8:before {
  content: "\eaeb";
  margin-left: -1.4501953125em;
  color: rgb(255, 255, 255);
}
.icon-AMEX .path9:before {
  content: "\eaec";
  margin-left: -1.4501953125em;
  color: rgb(255, 255, 255);
}
.icon-AMEX .path10:before {
  content: "\eaed";
  margin-left: -1.4501953125em;
  color: rgb(255, 255, 255);
}
.icon-Unkown-Card .path1:before {
  content: "\ea6d";
  color: rgb(129, 136, 146);
}
.icon-Unkown-Card .path2:before {
  content: "\ea6e";
  margin-left: -1.49609375em;
  color: rgb(116, 109, 109);
}
.icon-Maestro .path1:before {
  content: "\ea74";
  color: rgb(108, 107, 189);
}
.icon-Maestro .path2:before {
  content: "\ea75";
  margin-left: -1.2880859375em;
  color: rgb(211, 32, 17);
}
.icon-Maestro .path3:before {
  content: "\ea76";
  margin-left: -1.2880859375em;
  color: rgb(0, 153, 223);
}
.icon-Maestro .path4:before {
  content: "\ea77";
  margin-left: -1.2880859375em;
  color: rgb(17, 15, 13);
}
.icon-Interac .path1:before {
  content: "\ea7c";
  color: rgb(255, 202, 5);
}
.icon-Interac .path2:before {
  content: "\ea7d";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path3:before {
  content: "\ea7e";
  margin-left: -0.994140625em;
  color: rgb(255, 202, 5);
}
.icon-Interac .path4:before {
  content: "\ea7f";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path5:before {
  content: "\ea80";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path6:before {
  content: "\ea81";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path7:before {
  content: "\ea82";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path8:before {
  content: "\ea83";
  margin-left: -0.994140625em;
  color: rgb(255, 255, 255);
}
.icon-Interac .path9:before {
  content: "\ea84";
  margin-left: -0.994140625em;
  color: rgb(255, 255, 255);
}
.icon-Interac .path10:before {
  content: "\ea85";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path11:before {
  content: "\ea86";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path12:before {
  content: "\ea87";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path13:before {
  content: "\ea88";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path14:before {
  content: "\ea89";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path15:before {
  content: "\ea8a";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Interac .path16:before {
  content: "\ea8b";
  margin-left: -0.994140625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path1:before {
  content: "\ea8c";
  color: rgb(255, 255, 255);
}
.icon-Discover .path2:before {
  content: "\ea8d";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path3:before {
  content: "\ea8e";
  margin-left: -1.5634765625em;
  color: rgb(245, 130, 32);
}
.icon-Discover .path4:before {
  content: "\ea8f";
  margin-left: -1.5634765625em;
  color: rgb(0, 0, 0);
}
.icon-Discover .path5:before {
  content: "\ea90";
  margin-left: -1.5634765625em;
  color: rgb(0, 23, 34);
}
.icon-Discover .path6:before {
  content: "\ea91";
  margin-left: -1.5634765625em;
  color: rgb(0, 23, 34);
}
.icon-Discover .path7:before {
  content: "\ea92";
  margin-left: -1.5634765625em;
  color: rgb(0, 23, 34);
}
.icon-Discover .path8:before {
  content: "\ea93";
  margin-left: -1.5634765625em;
  color: rgb(0, 23, 34);
}
.icon-Discover .path9:before {
  content: "\ea94";
  margin-left: -1.5634765625em;
  color: rgb(0, 23, 34);
}
.icon-Discover .path10:before {
  content: "\ea95";
  margin-left: -1.5634765625em;
  color: rgb(0, 23, 34);
}
.icon-Discover .path11:before {
  content: "\ea96";
  margin-left: -1.5634765625em;
  color: rgb(0, 23, 34);
}
.icon-Discover .path12:before {
  content: "\ea97";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path13:before {
  content: "\ea98";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path14:before {
  content: "\ea9d";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path15:before {
  content: "\ea9e";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path16:before {
  content: "\ea9f";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path17:before {
  content: "\eaa0";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path18:before {
  content: "\eaa1";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path19:before {
  content: "\eaa2";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path20:before {
  content: "\eaa3";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path21:before {
  content: "\eaa4";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path22:before {
  content: "\eaa5";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path23:before {
  content: "\eaa6";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path24:before {
  content: "\eaa7";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path25:before {
  content: "\eaa8";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path26:before {
  content: "\eaa9";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path27:before {
  content: "\eaaa";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path28:before {
  content: "\eaab";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Discover .path29:before {
  content: "\eaac";
  margin-left: -1.5634765625em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path1:before {
  content: "\eaad";
  color: rgb(0, 74, 152);
}
.icon-Diners-Club .path2:before {
  content: "\eaae";
  margin-left: -1.44921875em;
  color: rgb(255, 255, 255);
}
.icon-Diners-Club .path3:before {
  content: "\eaaf";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path4:before {
  content: "\eab0";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path5:before {
  content: "\eab1";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path6:before {
  content: "\eab2";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path7:before {
  content: "\eab3";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path8:before {
  content: "\eab4";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path9:before {
  content: "\eab5";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path10:before {
  content: "\eab6";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path11:before {
  content: "\eab7";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path12:before {
  content: "\eab8";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path13:before {
  content: "\eab9";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path14:before {
  content: "\eaba";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path15:before {
  content: "\eabb";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path16:before {
  content: "\eabc";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path17:before {
  content: "\eabd";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path18:before {
  content: "\eabe";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path19:before {
  content: "\eabf";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path20:before {
  content: "\eac0";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path21:before {
  content: "\eac1";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path22:before {
  content: "\eac2";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path23:before {
  content: "\eac3";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path24:before {
  content: "\eac4";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path25:before {
  content: "\eac5";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path26:before {
  content: "\eac6";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path27:before {
  content: "\eac7";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path28:before {
  content: "\eac8";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path29:before {
  content: "\eac9";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path30:before {
  content: "\eaca";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path31:before {
  content: "\eacb";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path32:before {
  content: "\eacc";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path33:before {
  content: "\eacd";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path34:before {
  content: "\eace";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path35:before {
  content: "\eacf";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path36:before {
  content: "\ead0";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path37:before {
  content: "\ead1";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path38:before {
  content: "\ead2";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-Diners-Club .path39:before {
  content: "\ead3";
  margin-left: -1.44921875em;
  color: rgb(35, 31, 32);
}
.icon-China-UnionPay .path1:before {
  content: "\ead4";
  color: rgb(221, 2, 40);
}
.icon-China-UnionPay .path2:before {
  content: "\ead5";
  margin-left: -1.5595703125em;
  color: rgb(2, 67, 129);
}
.icon-China-UnionPay .path3:before {
  content: "\ead6";
  margin-left: -1.5595703125em;
  color: rgb(1, 121, 138);
}
.icon-China-UnionPay .path4:before {
  content: "\ead7";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path5:before {
  content: "\ead8";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path6:before {
  content: "\ead9";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path7:before {
  content: "\eada";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path8:before {
  content: "\eadb";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path9:before {
  content: "\eadc";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path10:before {
  content: "\eadd";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path11:before {
  content: "\eade";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path12:before {
  content: "\eadf";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path13:before {
  content: "\eae0";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path14:before {
  content: "\eae1";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path15:before {
  content: "\eae2";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path16:before {
  content: "\eae3";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path17:before {
  content: "\eae4";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path18:before {
  content: "\eae5";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-China-UnionPay .path19:before {
  content: "\eae6";
  margin-left: -1.5595703125em;
  color: rgb(255, 255, 255);
}
.icon-hsbc .path1:before {
  content: "\ea65";
  color: rgb(0, 0, 0);
}
.icon-hsbc .path2:before {
  content: "\ea66";
  margin-left: -5.662109375em;
  color: rgb(0, 0, 0);
}
.icon-hsbc .path3:before {
  content: "\ea67";
  margin-left: -5.662109375em;
  color: rgb(0, 0, 0);
}
.icon-hsbc .path4:before {
  content: "\ea68";
  margin-left: -5.662109375em;
  color: rgb(0, 0, 0);
}
.icon-hsbc .path5:before {
  content: "\ea69";
  margin-left: -5.662109375em;
  color: rgb(237, 55, 37);
}
.icon-hsbc .path6:before {
  content: "\ea6a";
  margin-left: -5.662109375em;
  color: rgb(237, 55, 37);
}
.icon-hsbc .path7:before {
  content: "\ea6b";
  margin-left: -5.662109375em;
  color: rgb(237, 55, 37);
}
.icon-hsbc .path8:before {
  content: "\ea6c";
  margin-left: -5.662109375em;
  color: rgb(237, 55, 37);
}
.icon-visa .path1:before {
  content: "\e9e2";
  color: rgb(0, 88, 160);
}
.icon-visa .path2:before {
  content: "\e9e3";
  margin-left: -1.5009765625em;
  color: rgb(255, 255, 255);
}
.icon-visa .path3:before {
  content: "\e9e4";
  margin-left: -1.5009765625em;
  color: rgb(0, 88, 160);
}
.icon-visa .path4:before {
  content: "\e9e5";
  margin-left: -1.5009765625em;
  color: rgb(0, 88, 160);
}
.icon-visa .path5:before {
  content: "\e9e6";
  margin-left: -1.5009765625em;
  color: rgb(0, 88, 160);
}
.icon-visa .path6:before {
  content: "\e9e7";
  margin-left: -1.5009765625em;
  color: rgb(0, 88, 160);
}
.icon-visa .path7:before {
  content: "\e9e8";
  margin-left: -1.5009765625em;
  color: rgb(250, 166, 26);
}
.icon-master-card .path1:before {
  content: "\e9e9";
  color: rgb(252, 177, 49);
}
.icon-master-card .path2:before {
  content: "\e9ea";
  margin-left: -1.6611328125em;
  color: rgb(252, 177, 49);
}
.icon-master-card .path3:before {
  content: "\e9eb";
  margin-left: -1.6611328125em;
  color: rgb(237, 27, 46);
}
.icon-master-card .path4:before {
  content: "\e9ec";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-master-card .path5:before {
  content: "\e9ed";
  margin-left: -1.6611328125em;
  color: rgb(0, 52, 115);
}
.icon-master-card .path6:before {
  content: "\e9ee";
  margin-left: -1.6611328125em;
  color: rgb(0, 52, 115);
}
.icon-master-card .path7:before {
  content: "\e9ef";
  margin-left: -1.6611328125em;
  color: rgb(0, 52, 115);
}
.icon-master-card .path8:before {
  content: "\e9f0";
  margin-left: -1.6611328125em;
  color: rgb(0, 52, 115);
}
.icon-master-card .path9:before {
  content: "\e9f1";
  margin-left: -1.6611328125em;
  color: rgb(0, 52, 115);
}
.icon-master-card .path10:before {
  content: "\e9f2";
  margin-left: -1.6611328125em;
  color: rgb(0, 52, 115);
}
.icon-master-card .path11:before {
  content: "\e9f3";
  margin-left: -1.6611328125em;
  color: rgb(0, 52, 115);
}
.icon-master-card .path12:before {
  content: "\e9f4";
  margin-left: -1.6611328125em;
  color: rgb(0, 52, 115);
}
.icon-master-card .path13:before {
  content: "\e9f5";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-master-card .path14:before {
  content: "\e9f6";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-master-card .path15:before {
  content: "\e9f7";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-master-card .path16:before {
  content: "\ea5e";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-master-card .path17:before {
  content: "\ea5f";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-master-card .path18:before {
  content: "\ea60";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-master-card .path19:before {
  content: "\ea61";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-master-card .path20:before {
  content: "\ea62";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-master-card .path21:before {
  content: "\ea63";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-master-card .path22:before {
  content: "\ea64";
  margin-left: -1.6611328125em;
  color: rgb(255, 255, 255);
}
.icon-advance-search:before {
  content: "\ea9c";
}
.icon-advanced-search:before {
  content: "\ea99";
}
.icon-dashboard-left-arrow:before {
  content: "\ea9a";
}
.icon-dashboard-right-arrow:before {
  content: "\ea9b";
}
.icon-Device:before {
  content: "\e9de";
}
.icon-cardType:before {
  content: "\e9df";
}
.icon-left-arrow1:before {
  content: "\e9e0";
}
.icon-right-arrow1:before {
  content: "\e9e1";
}
.icon-Plus:before {
  content: "\e9dc";
}
.icon-Minus:before {
  content: "\e9dd";
}
.icon-percent:before {
  content: "\e9db";
}
.icon-emptyshop .path1:before {
  content: "\e91d";
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-emptyshop .path2:before {
  content: "\e9c3";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-emptyshop .path3:before {
  content: "\e9c4";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-emptyshop .path4:before {
  content: "\e9c5";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-emptyshop .path5:before {
  content: "\e9c6";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-emptyshop .path6:before {
  content: "\e9c7";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
}
.icon-emptyshop .path7:before {
  content: "\e9c8";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
}
.icon-emptyshop .path8:before {
  content: "\e9c9";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
}
.icon-emptyshop .path9:before {
  content: "\e9ca";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
}
.icon-emptyshop .path10:before {
  content: "\e9cb";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
}
.icon-emptyshop .path11:before {
  content: "\e9cc";
  margin-left: -1.494140625em;
  color: rgb(255, 255, 255);
}
.icon-emptyshop .path12:before {
  content: "\e9cd";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
}
.icon-emptyshop .path13:before {
  content: "\e9ce";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
}
.icon-emptyshop .path14:before {
  content: "\e9cf";
  margin-left: -1.494140625em;
  color: rgb(197, 141, 11);
}
.icon-Shop_nocart .path1:before {
  content: "\e9d0";
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-Shop_nocart .path2:before {
  content: "\e9d1";
  margin-left: -1.6533203125em;
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-Shop_nocart .path3:before {
  content: "\e9d2";
  margin-left: -1.6533203125em;
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-Shop_nocart .path4:before {
  content: "\e9d3";
  margin-left: -1.6533203125em;
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-Shop_nocart .path5:before {
  content: "\e9d4";
  margin-left: -1.6533203125em;
  color: rgb(197, 141, 11);
  opacity: 0.1;
}
.icon-Shop_nocart .path6:before {
  content: "\e9d5";
  margin-left: -1.6533203125em;
  color: rgb(197, 141, 11);
}
.icon-Shop_nocart .path7:before {
  content: "\e9d6";
  margin-left: -1.6533203125em;
  color: rgb(197, 141, 11);
}
.icon-Shop_nocart .path8:before {
  content: "\e9d7";
  margin-left: -1.6533203125em;
  color: rgb(197, 141, 11);
}
.icon-Shop_nocart .path9:before {
  content: "\e9d8";
  margin-left: -1.6533203125em;
  color: rgb(197, 141, 11);
}
.icon-Shop_nocart .path10:before {
  content: "\e9d9";
  margin-left: -1.6533203125em;
  color: rgb(197, 141, 11);
}
.icon-Shop_nocart .path11:before {
  content: "\e9da";
  margin-left: -1.6533203125em;
  color: rgb(197, 141, 11);
}
.icon-Appointment:before {
  content: "\e900";
}
.icon-audit:before {
  content: "\e901";
}
.icon-shop:before {
  content: "\e902";
}
.icon-location-login:before {
  content: "\e903";
}
.icon-Search:before {
  content: "\e904";
}
.icon-Phone:before {
  content: "\e905";
}
.icon-Scheduled:before {
  content: "\e906";
}
.icon-Delete:before {
  content: "\e907";
}
.icon-Others:before {
  content: "\e908";
}
.icon-mail:before {
  content: "\e909";
}
.icon-Cash:before {
  content: "\e90a";
}
.icon-Card:before {
  content: "\e90b";
}
.icon-apppointments:before {
  content: "\e90c";
}
.icon-Cheque:before {
  content: "\e90d";
}
.icon-GiftCard:before {
  content: "\e90e";
}
.icon-Bell:before {
  content: "\e90f";
}
.icon-Cancel:before {
  content: "\e910";
}
.icon-Coins:before {
  content: "\e911";
}
.icon-SuccessDark:before {
  content: "\e912";
}
.icon-Edit:before {
  content: "\e913";
}
.icon-garbage:before {
  content: "\e914";
}
.icon-DragAndDrop:before {
  content: "\e915";
}
.icon-confirmed:before {
  content: "\e916";
}
.icon-Down-Arrow:before {
  content: "\e917";
}
.icon-Up-Arrow:before {
  content: "\e918";
}
.icon-up-arrow:before {
  content: "\e919";
}
.icon-More:before {
  content: "\e91a";
}
.icon-mug:before {
  content: "\e91b";
}
.icon-redo:before {
  content: "\e91c";
}
.icon-Group-197:before {
  content: "\e91e";
}
.icon-Group-198:before {
  content: "\e91f";
}
.icon-Group-199:before {
  content: "\e920";
}
.icon-scheduledclock:before {
  content: "\e921";
}
.icon-revenue:before {
  content: "\e922";
}
.icon-items:before {
  content: "\e923";
}
.icon-lunch:before {
  content: "\e924";
}
.icon-add:before {
  content: "\e925";
}
.icon-print:before {
  content: "\e926";
}
.icon-recurring:before {
  content: "\e927";
}
.icon-undo:before {
  content: "\e928";
}
.icon-link:before {
  content: "\e929";
}
.icon-recap:before {
  content: "\e92a";
}
.icon-multi-appointments:before {
  content: "\e92b";
}
.icon-spa-package:before {
  content: "\e92c";
}
.icon-donotmove:before {
  content: "\e92d";
}
.icon-Success:before {
  content: "\e92e";
}
.icon-paid:before {
  content: "\e92f";
}
.icon-repeat-client:before {
  content: "\e930";
}
.icon-request-staff:before {
  content: "\e931";
}
.icon-addons:before {
  content: "\e932";
}
.icon-comments:before {
  content: "\e933";
}
.icon-adddeposit:before {
  content: "\e934";
}
.icon-hotel-guest:before {
  content: "\e935";
}
.icon-member:before {
  content: "\e936";
}
.icon-vip:before {
  content: "\e937";
}
.icon-male-staff:before {
  content: "\e938";
}
.icon-female-staff:before {
  content: "\e939";
}
.icon-transaction:before {
  content: "\e93a";
}
.icon-cancel:before {
  content: "\e93b";
}
.icon-enforce-gender:before {
  content: "\e93c";
}
.icon-overbook:before {
  content: "\e93d";
}
.icon-no-staff:before {
  content: "\e93e";
}
.icon-move:before {
  content: "\e93f";
}
.icon-copy:before {
  content: "\e940";
}
.icon-changeservice:before {
  content: "\e941";
}
.icon-zoom-in:before {
  content: "\e942";
}
.icon-legends:before {
  content: "\e943";
}
.icon-Revenue:before {
  content: "\e944";
}
.icon-Item:before {
  content: "\e945";
}
.icon-zoom-out:before {
  content: "\e946";
}
.icon-user:before {
  content: "\e947";
}
.icon-Calender:before {
  content: "\e948";
}
.icon-left-arrow:before {
  content: "\e949";
}
.icon-right-arrow:before {
  content: "\e94a";
}
.icon-sendmail:before {
  content: "\e94b";
}
.icon-hair:before {
  content: "\e94c";
}
.icon-wax:before {
  content: "\e94d";
}
.icon-face:before {
  content: "\e94e";
}
.icon-body:before {
  content: "\e94f";
}
.icon-filter_filled:before {
  content: "\e950";
}
.icon-clock:before {
  content: "\e951";
}
.icon-information:before {
  content: "\e952";
}
.icon-deposit:before {
  content: "\e953";
}
.icon-note:before {
  content: "\e954";
}
.icon-close:before {
  content: "\e955";
}
.icon-done:before {
  content: "\e956";
}
.icon-Call:before {
  content: "\e957";
}
.icon-checkout:before {
  content: "\e958";
}
.icon-checkin:before {
  content: "\e959";
}
.icon-disabled-user:before {
  content: "\e95a";
  color: #a73535;
}
.icon-enabled-user:before {
  content: "\e95b";
  color: #56a948;
}
.icon-password-hide:before {
  content: "\e95c";
}
.icon-profile:before {
  content: "\e95d";
}
.icon-swap:before {
  content: "\e95e";
}
.icon-serviceaddon:before {
  content: "\e95f";
}
.icon-therapists:before {
  content: "\e960";
}
.icon-woman:before {
  content: "\e961";
}
.icon-Location:before {
  content: "\e962";
}
.icon-repeatclients:before {
  content: "\e963";
}
.icon-newclients:before {
  content: "\e964";
}
.icon-noshows:before {
  content: "\e965";
}
.icon-alert:before {
  content: "\e966";
}
.icon-checkedout:before {
  content: "\e967";
}
.icon-checkedin:before {
  content: "\e968";
}
.icon-scheduled:before {
  content: "\e969";
}
.icon-commission:before {
  content: "\e96a";
}
.icon-client:before {
  content: "\e96b";
}
.icon-servicecharge:before {
  content: "\e96c";
}
.icon-gratuity:before {
  content: "\e96d";
}
.icon-hash:before {
  content: "\e96e";
}
.icon-warning-info-icon:before {
  content: "\e96f";
}
.icon-success-icon:before {
  content: "\e970";
}
.icon-warning-icon:before {
  content: "\e971";
}
.icon-error-icon:before {
  content: "\e972";
}
.icon-info-icon:before {
  content: "\e973";
}
.icon-filter:before {
  content: "\e976";
}
.icon-password-show:before {
  content: "\e9c2";
}
.icon-location:before {
  content: "\e9c1";
}
.icon-password:before {
  content: "\e9bd";
}
.icon-userid:before {
  content: "\e9be";
}
.icon-appointment-calender:before {
  content: "\e9bf";
}
.icon-timepicker:before {
  content: "\e9c0";
}
.icon-left-arrow-dashboard:before {
  content: "\e977";
}
.icon-right-arrow-dashboard:before {
  content: "\e978";
}
.icon-sortArrow:before {
  content: "\e974";
  color: #6b6b6b;
}
.icon-filled-down-arrow:before {
  content: "\e9bb";
  color: #6b6b6b;
}
.icon-zoom-out1:before {
  content: "\e9bc";
}
.icon-zoom-in1:before {
  content: "\ecb4";
}