$navy-blue-darkest: rgb(38,101,192);
$navy-blue-darker: rgb(77,123,189);
$navy-blue: rgb(96,142,209);
$navy-blue-lighter: rgb(212,224,242);
$navy-blue-lightest:rgb(232,239,248);

$white: rgb(255,255,255);
$black: rgb(0,0,0);
$black-light: rgb(74,72,72);
$black-lighter: rgba(0, 0, 0, 0.54);
$black-lightest: rgba(0, 0, 0, 0.12);

//Grey
$grey-darkest: rgb(67,74,84);
$grey-darker: rgb(129,136,146);
$grey-dark: rgb(149,152,154);
$grey-medium1: rgb(161,166,173);
$grey-medium2: rgb(171,173,174);
$grey-light: rgb(195,195,195);
$grey-lighter: rgb(228,229,232);
$grey-lightest: rgb(244,244,245);
$grey-lightest1: unquote("#f5f4f6");


//Green
$green-darkest: rgb(67,160,71);
$green:rgb(15,183,22);
$green-lightest: rgb(203,255,204);

//Orange
$orange: rgb(222,137,20);
$orange-lightest: rgb(255,240,220);

//Red
$red: rgb(224,4,4);
$red-lightest: rgb(255,225,219);

//Blue
$blue: rgb(83,147,225);
$blue-lightest: rgb(220,233,249);
$aqua-blue:unquote("#daf0fd");
$blue-lighter:unquote("#d2e3ed");
$blue-light: unquote("#F0F4FA");


//Yellow
$yellow: unquote("#FFFF8D");

$tabTextGray: unquote('#818892');
