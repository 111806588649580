body.new-mat-view {
  .mat-form-field.mat-form-field-can-float.mat-form-field-should-float {
      &.mat-form-field {
          margin-top: 25px;
      }
      // Label Styling
      .mat-form-field-label,
      .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
          transform: translateY(-1.28125em) perspective(100px) translateZ(0.001px);
          font-size: 14px;
      }
      .mat-form-field-label-wrapper {
          top: -1.5em;
      }
      &.mat-form-field-appearance-legacy {
          .mat-form-field-infix {
              border: 1px solid grey;
              border-radius: 4px;
              padding: 10px 8px;
              height: 40px;
          }
      }
      &.mat-form-field-disabled {
          .mat-form-field-infix {
              opacity: 0.6;
          }
      }
      .mat-form-field-underline {
          display: none;
      }
      &.mat-form-field-invalid {
          .mat-form-field-infix {
              border: 1px solid red;
          }
      }
  }
  //Text Area
  .txt-area-height {
      border: 1px solid grey;
      border-radius: 4px;
      .mat-form-field-wrapper{
          padding-bottom: 6px !important;
          .mat-form-field-flex{
              .mat-form-field-infix {
                  border: none !important;
              }
          }
      }
  }
  //Datepicker
  app-ag-date-picker {
      .mat-form-field.mat-form-field-can-float.mat-form-field-should-float.mat-form-field-appearance-legacy {
          .mat-form-field-infix {
              padding: 0.6em 25px 0.6em 4px;
          }
      }
      .mat-form-field-suffix {
          position: absolute;
          top: 7px;
          right: 4px;
      }
  }
  //mat-datepicker
  .mat-form-field.mat-form-field-can-float.mat-form-field-should-float.mat-form-field-appearance-legacy.date-picker-width {
      .mat-form-field-infix {
          padding: 0.6em 25px 0.6em 4px;
      }
  }
  .mat-form-field.mat-form-field-can-float.mat-form-field-should-float.mat-form-field-appearance-legacy.date-picker-width {
      .mat-form-field-suffix{
          position: absolute;
          top: 10px;
          right: 5px;
      }
  }
  //PlusMinus Alignemnt
  .ag-address {
      .icon-minus,
      .icon-plus {
          margin-top: 30px !important;
      }
  }
  .ph-fixed-width--wrapper {
      .icon-Minus,
      .icon-Plus,
      .icon-minus,
      .icon-plus {
          margin-top: 30px !important;
      }
  }
  .phone-wrapper {
      .ag-phone-switch {
          padding-top: 5px !important;
      }
  }
  .mail-wrapper {
      .ag-mail-switch {
          padding-top: 5px !important;
      }
  }
  .private-info-toggle{
      padding-top: 5px !important;
  }
  .mail-wrapper {
      .fixed-width--wrapper {
          .icon-minus,
          .icon-plus,
          .icon-Minus,
          .icon-Plus {
              margin-top: 30px !important;
              //font-size: 2rem;
          }
      }
      .mat-form-field.ag_form-control--md {
          padding-right: 0;
      }
  }

  //Layout Changes
  @for $i from 1 through 4 {
      .ag_form-control--#{$i} {
          width: #{$i * 25} + "%";
          mat-form-field {
              width: 100%;
          }
      }
  }
  // .phone-wrapper {
  //     width: 100%;
  //     .ph-fixed-width--wrapper{
  //         width: 100%;
  //         .mat-form-field{
  //             width: 25% !important;
  //             padding-right: 3rem !important;
  //         }
  //     }
  //     .ag-phone-switch{
  //         padding-left: 60px !important;
  //     }
  // }
  // .mail-wrapper {
  //     width: 100%;
  //     .fixed-width--wrapper{
  //         width: 100%;
  //         .mat-form-field{
  //             width: 25% !important;
  //             padding-right: 3rem !important;
  //         }
  //     }
  //     .ag-mail-switch{
  //         padding-left: 60px !important;
  //     }
  // }

  // cdk table datepicker change
  .ag_table .datepicker{
      .mat-form-field.mat-form-field-can-float.mat-form-field-should-float {
        &.mat-form-field {
            margin-top: 8px !important;
        }
        &.mat-form-field-appearance-legacy {
          .mat-form-field-infix {
              height: 32px !important;
              padding: 0px !important;
          }
          .mat-form-field-suffix{
            .mat-icon-button{
              padding-left: 15px !important
            }
          }
      }
    }
      .table-input{
        border: none !important;
      }
    }
}

@media only screen and (min-width: 1024px) {
  body.new-mat-view {
      //Layout Changes
      @for $i from 1 through 4 {
          .ag_form-control--#{$i} {
              width: #{$i * 25} + "%";
              mat-form-field {
                  width: 100%;
              }
          }
      }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  body.new-mat-view {
      //Layout Changes
      @for $i from 1 through 4 {
          .ag_form-control--#{$i} {
              width: 50%;
              mat-form-field {
                  width: 100%;
              }
          }
      }
  }
}
@media only screen and (max-width: 768px) {
  body.new-mat-view {
      //Layout Changes
      @for $i from 1 through 4 {
          .ag_form-control--#{$i} {
              width: 100%;
              mat-form-field {
                  width: 100%;
                  padding-right: 0 !important;
              }
          }
      }
  }
}
@media only screen and (min-width: 1024px) {
  body.new-mat-view {
          .ag_form-control--link {
              margin-right: -2.5rem;
              mat-form-field {
                  width: 100%;
              }
          }
  }
}


//    --label color change--

.ag_form--label {
  color: $black !important;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: $black!important;
}

